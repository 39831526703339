import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { FormularFach } from '../../../models/formular-fach';
import { RPZeugnis } from '../../../models/rp-zeugnis';
import { WorkspaceService } from '../../../shared/services';
import { RPProtokollFormular } from './rp-protokoll-formular';
import { RPSchuelerComponent } from '../projectables/rp-schueler.component';
import { KPBlockFactoryComponent } from '../../kp/kp-block-factory.component';
import { RPFachHeaderComponent } from '../projectables/rp-fach-header.component';
import { RPSchulortProtokollComponent } from '../projectables/rp-schulort-protokoll.component';
import { FormularViewType } from '../../formular/formular';

interface Option<T> {
  label: string;
  value: T;
}
interface Ref<T> {
  ref: T;
}
export class RP234hProtokollFormular extends RPProtokollFormular {
  private _visibleOptions: Option<Ref<string>>[] = [];
  private _visibleSelected: Ref<string>[] = [];

  constructor(zeugnis: RPZeugnis, category: string) {
    super(zeugnis, category);
  }
  get viewType(): FormularViewType {
    return RP234hProtokollFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    if (this.isKP) {
      faecher.push(
        this.religion,
        this.deutsch,
        this.mathematik,
        this.sachunterricht,
        this.musik,
        this.sport,
        this.bildendeKunst
      );
      if (this.herkunftssprache.gesamt.isVisibleOverrideDefaultFalse) faecher.push(this.herkunftssprache);
      if (this.herkunftssprache2.gesamt.isVisibleOverrideDefaultFalse) faecher.push(this.herkunftssprache2);
    }
    return faecher;
  }
  get visibleOptions(): Option<Ref<string>>[] {
    const options: Option<Ref<string>>[] = [];
    options.push({ label: 'Beteiligte Personen', value: { ref: 'beteiligtePersonen' } });
    for (const fach of this.faecher) {
      if (fach === this.herkunftssprache2) {
        options.push({ label: `2. ${fach.description.label}`, value: { ref: fach.key } });
      } else options.push({ label: fach.description.label, value: { ref: fach.key } });
    }
    if (this.isKP) options.push({ label: 'Schrift', value: { ref: 'schrift' } });
    options.push({ label: 'Unterschrift Schüler', value: { ref: 'unterschriftSchueler' } });
    if (
      this._visibleOptions.length !== options.length ||
      this._visibleOptions.some((v, index) => v.value.ref !== options[index].value.ref)
    ) {
      this._visibleOptions.splice(0, this._visibleOptions.length);
      this._visibleOptions.push(...options);
    }
    return this._visibleOptions;
  }
  get visibleSelected(): Ref<string>[] {
    const selected: Ref<string>[] = [];
    if (this.zeugnis.beteiligtePersonenVisibleOverride ?? true) selected.push({ ref: 'beteiligtePersonen' });
    for (const fach of this.faecher) {
      if (fach.gesamt.isVisibleOverrideZusatztextDefaultTrue) selected.push({ ref: fach.key });
    }
    if (this.schrift.gesamt.isVisibleOverrideZusatztextDefaultFalse) selected.push({ ref: 'schrift' });
    if (this.zeugnis.unterschriftSchuelerVisibleOverride ?? false) selected.push({ ref: 'unterschriftSchueler' });
    if (
      this._visibleSelected.length !== selected.length ||
      this._visibleSelected.some((v, index) => v.ref !== selected[index].ref)
    ) {
      this._visibleSelected.splice(0, this._visibleSelected.length);
      this._visibleSelected.push(...selected);
    }
    return this._visibleSelected;
  }

  visibleChange(value: Option<Ref<string>>): void {
    const ref = value.value.ref;
    if (ref === 'beteiligtePersonen') {
      this.zeugnis.beteiligtePersonenVisibleOverride =
        this.zeugnis.beteiligtePersonenVisibleOverride === null ? false : null;
    } else if (ref === 'unterschriftSchueler') {
      this.zeugnis.unterschriftSchuelerVisibleOverride =
        this.zeugnis.unterschriftSchuelerVisibleOverride === null ? true : null;
    } else if (ref === 'schrift') {
      const fachGesamt = this.zeugnis.faecher.getItem(ref).bereiche.getItem(null);
      fachGesamt.isVisibleOverrideZusatztextDefaultFalse = !fachGesamt.isVisibleOverrideZusatztextDefaultFalse;
    } else {
      const fachGesamt = this.zeugnis.faecher.getItem(ref).bereiche.getItem(null);
      fachGesamt.isVisibleOverrideZusatztextDefaultTrue = !fachGesamt.isVisibleOverrideZusatztextDefaultTrue;
    }
  }

  copyVisible(targetZeugnisse: RPZeugnis[]): void {
    for (const targetZeugnis of targetZeugnisse) {
      const targetFormular = new RP234hProtokollFormular(targetZeugnis, this.category);
      targetZeugnis.beteiligtePersonenVisibleOverride = this.zeugnis.beteiligtePersonenVisibleOverride;
      if (this.isKP) {
        for (const fach of this.faecher) {
          const targetFach = targetFormular.faecher.find((f) => f.key === fach.key);
          if (targetFach != null) {
            targetFach.gesamt.bereich.isVisibleOverrideZusatztext = fach.gesamt.bereich.isVisibleOverrideZusatztext;
          }
        }
        targetFormular.schrift.gesamt.bereich.isVisibleOverrideZusatztext =
          this.schrift.gesamt.bereich.isVisibleOverrideZusatztext;
      }
      targetZeugnis.unterschriftSchuelerVisibleOverride = this.zeugnis.unterschriftSchuelerVisibleOverride;
    }
  }
}

@Component({
  selector: 'fz-rp-234h-protokoll-formular',
  templateUrl: 'rp-234h-protokoll-formular.component.html',
})
export class RP234hProtokollFormularComponent extends FormularComponentBase<RP234hProtokollFormular> {
  static createModel(formular: RP234hProtokollFormular) {
    return {
      zeugnissatz: {
        title: 'Protokoll',
        subtitle: 'des Gesprächs über die allgemeine Lern- und Leistungsentwicklung',
        text: `gem. §39 (${formular.zeugnis.zeugnissatz.klassenstufe === 2 ? '3' : '4'}) der Schulordnung für die
        öffentlichen Grundschulen`,
      },
      schueler: RPSchuelerComponent.createModel(formular),
      beteiligtePersonen:
        (formular.zeugnis.beteiligtePersonenVisibleOverride ?? true)
          ? {
              richTextField: formular.beteiligtePersonen,
            }
          : undefined,
      sozialLernArbeitsverhaltenLernentwicklung: {
        headerLabel: formular.protokolltext.description.label,
        kp: formular.isKP
          ? KPBlockFactoryComponent.createModelFromFach(formular.sozialLernArbeitsverhalten)
          : undefined,
        textField: formular.protokolltext,
      },
      faecher: formular.faecher
        .filter((fach) => formular.isKP && fach.gesamt.isVisibleOverrideZusatztextDefaultTrue)
        .map((fach) => ({
          trackBy: fach.key,
          key: fach.key,
          headerLabel: fach.gesamt.description.label || '',
          header: RPFachHeaderComponent.createModel(fach, formular),
          kp: formular.isKP ? KPBlockFactoryComponent.createModelFromFach(fach) : undefined,
          textField: fach.gesamt.zusatztext,
        })),
      schrift:
        formular.isKP && formular.schrift.gesamt.isVisibleOverrideZusatztextDefaultFalse
          ? {
              headerLabel: formular.schrift.description.label,
              textField: formular.schrift.gesamt.text,
            }
          : undefined,
      ziel: {
        headerLabel: formular.ziel.description.label,
        textField: formular.ziel,
      },
      schulort: RPSchulortProtokollComponent.createModel(formular),
      unterschrift: {
        klassenleitungField: formular.zeugnissatz.klassenleitung,
        schuelerSelectField: formular.unterschriftSchuelerVisibleOverride,
        schuelerLabel: formular.schueler.label,
      },
    };
  }

  get model() {
    return RP234hProtokollFormularComponent.createModel(this.formular);
  }

  loading: boolean = false;
  constructor(
    formularService: FormularService<RP234hProtokollFormular>,
    private workspaceService: WorkspaceService
  ) {
    super(formularService);
  }

  getFach(key: string) {
    return this.formular.faecher.find((f) => f.key === key);
  }

  async applyVisibleToAll() {
    try {
      this.loading = true;
      this.formular.copyVisible(this.workspaceService.selectedZeugnisse as RPZeugnis[]);
      await this.workspaceService.saveSelectedZeugnissatz();
    } finally {
      this.loading = false;
    }
  }
}

import { NodeHelper, NodeWalker } from '@modules/dom';
import { TextRange } from '@modules/pdl/text-range';
import itiriri from 'itiriri';

export interface LineDescription {
  fragment: DocumentFragment;
  height: number;
  hasBullet: boolean;
  bulletWidth: number;
  bulletLength: number;
}

export class Line implements TextRange {
  public readonly prevLine: Line | null;
  public readonly top: number;
  public readonly height: number;
  public readonly isLastInParagraph: boolean;
  public readonly isInLastParagraph: boolean;
  public readonly className: string;
  public readonly start: number;
  public readonly length: number;
  public readonly fragment: DocumentFragment;
  public readonly indentWidth: number;
  public readonly hasBullet: boolean;
  public readonly bulletWidth: number;
  public readonly bulletLength: number;
  public readonly endsWithNewline: boolean;

  readonly #fragment: DocumentFragment;

  constructor(
    description: LineDescription,
    prevLine: Line | null,
    paragraphStart: number,
    paragraphTop: number,
    isLastInParagraph: boolean,
    className: string,
    isInLastParagraph: boolean
  ) {
    this.#fragment = description.fragment;
    this.fragment = NodeHelper.cloneContents(this.#fragment);
    this.prevLine = prevLine;
    this.height = description.height;
    this.isLastInParagraph = isLastInParagraph;
    this.isInLastParagraph = isInLastParagraph;
    this.className = className;
    this.top = this.prevLine == null ? paragraphTop : this.prevLine.top + this.prevLine.height;
    this.start = this.prevLine == null ? paragraphStart : this.prevLine.start + this.prevLine.length + 1;
    const walker = new NodeWalker(this.#fragment);
    this.length = itiriri(walker.textPositions).last()?.index ?? 0;
    NodeHelper.replaceSpaceWithNbsp(this.fragment);
    this.indentWidth = !description.hasBullet ? description.bulletWidth : 0;
    this.hasBullet = description.hasBullet;
    this.bulletLength = description.bulletLength;
    this.bulletWidth = description.bulletWidth;
    this.endsWithNewline = isLastInParagraph && !isInLastParagraph;
  }

  // public get html() {
  //   return this.innerHTML;
  // }

  // public cloneFragment() {
  //   const fragment = NodeHelper.cloneContents(this.#fragment);
  //   const walker = new NodeWalker(fragment);
  //   for (const textNode of walker.textNodes) {
  //     textNode.textContent = textNode.textContent!.replace(/\u200b/g, '');
  //   }
  //   return fragment;
  // }
}

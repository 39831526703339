import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { FormularBereich } from '../../../models/formular-bereich';
import { FormularFach } from '../../../models/formular-fach';
import { HEZeugnisFormular } from './he-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';
import { HEZeugnistypComponent } from '../projectables/he-zeugnistyp.component';
import { HESchuelerComponent } from '../projectables/he-schueler.component';
import { HEZeugnissatzFoerderschwerpunktComponent } from '../projectables/he-zeugnissatz-foerderschwerpunkt.component';
import { HEVersaeumnisComponent } from '../projectables/he-versaeumnis.component';
import { HEUnterschriftComponent } from '../projectables/he-unterschrift.component';
import { HEBereichHeaderComponent } from '../projectables/he-bereich-header.component';

export class HE1234hjLernFormular extends HEZeugnisFormular {
  get viewType(): FormularViewType {
    return HE1234hjLernFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    return faecher;
  }

  get bereiche(): FormularBereich[] {
    return [
      this.sozialLernArbeitsverhalten.lernArbeitsverhalten,
      this.sozialLernArbeitsverhalten.sozialverhalten,
      this.deutsch.gesamt,
      this.mathematik.gesamt,
      this.sachunterricht.gesamt,
      this.religion.gesamt,
      this.sport.gesamt,
      this.bildendeKunst.gesamt,
      this.musik.gesamt,
      this.fremdsprache.gesamt,
    ];
  }
}

@Component({
  selector: 'fz-he-1234hj-lern-formular',
  templateUrl: 'he-1234hj-lern-formular.component.html',
})
export class HE1234hjLernFormularComponent extends FormularComponentBase<HE1234hjLernFormular> {
  static createModel(formular: HE1234hjLernFormular) {
    return {
      zeugnistyp: HEZeugnistypComponent.createModel(formular),
      schueler: HESchuelerComponent.createModel(formular),
      zeugnissatz: HEZeugnissatzFoerderschwerpunktComponent.createModel(formular),
      bereiche: formular.bereiche.map((bereich) => ({
        trackBy: `${bereich.fach.key}_${bereich.key}`,
        label: bereich.description.label,
        header: HEBereichHeaderComponent.createModel(bereich, formular),
        textField: bereich.text,
      })),
      herkunftsspracheVisibleField: formular.herkunftssprache.gesamt.isVisibleOverrideDefaultTrueField,
      herkunftssprache: formular.herkunftssprache.gesamt.isVisibleOverrideDefaultTrue
        ? {
            textField: formular.herkunftssprache.gesamt.text,
          }
        : undefined,
      sprachkompetenzVisibleField: formular.sprachkompetenz.gesamt.isVisibleOverrideDefaultTrueField,
      sprachkompetenz: formular.sprachkompetenz.gesamt.isVisibleOverrideDefaultTrue
        ? {
            textField: formular.sprachkompetenz.gesamt.text,
          }
        : undefined,
      bemerkungen: {
        label: formular.bemerkungen.description.label,
        textField: formular.bemerkungen,
      },
      versaeumnis: HEVersaeumnisComponent.createModel(formular),
      unterschrift: HEUnterschriftComponent.createModel(formular),
    };
  }

  get model() {
    return HE1234hjLernFormularComponent.createModel(this.formular);
  }

  constructor(formularService: FormularService<HE1234hjLernFormular>) {
    super(formularService);
  }
}

import { Type } from '@angular/core';
import { FormularCategory, Formularsatz, HalbjahrDescription } from '@modules/formular/formularsatz';
import { BWFormular } from './formulare/bw-formular';
import { BW12hjStandardFormular } from './formulare/bw-12hj-standard-formular.component';
import { BW34hStandardFormular } from './formulare/bw-34h-standard-formular.component';
import { BW34jStandardFormular } from './formulare/bw-34j-standard-formular.component';
import { BW1234hjBeilageFormular } from './formulare/bw-1234hj-beilage-formular.component';
import { BundeslandTyp } from '../../models/enums/bundesland-typ';
import { Zeugnissatz } from '../../models/zeugnissatz';
import { BWZeugnis } from '../../models/bw-zeugnis';
import { Zeugnis } from '../../models/zeugnis';
import { NoteCandidateDescription } from '../../models/fields/note-field';

export type BWFormularCategoryKey = 'zeugnis' | 'beilage';

export class BWFormularsatz extends Formularsatz {
  get zeugnisType(): Type<Zeugnis> {
    return BWZeugnis;
  }

  get bundesland(): BundeslandTyp {
    return 'BW';
  }
  getAllHalbjahre(schuljahr: number): HalbjahrDescription[] {
    return [
      {
        text: '1. Klasse, Halbjahreszeugnis',
        klassenstufe: 1,
        halbjahr: 0,
        schuljahr,
      },
      {
        text: '1. Klasse, Jahreszeugnis',
        klassenstufe: 1,
        halbjahr: 1,
        schuljahr,
      },
      {
        text: '2. Klasse, Halbjahreszeugnis',
        klassenstufe: 2,
        halbjahr: 0,
        schuljahr,
      },
      {
        text: '2. Klasse, Jahreszeugnis',
        klassenstufe: 2,
        halbjahr: 1,
        schuljahr,
      },
      {
        text: '3. Klasse, Halbjahreszeugnis',
        klassenstufe: 3,
        halbjahr: 0,
        schuljahr,
      },
      {
        text: '3. Klasse, Jahreszeugnis',
        klassenstufe: 3,
        halbjahr: 1,
        schuljahr,
      },
      {
        text: '4. Klasse, Halbjahreszeugnis',
        klassenstufe: 4,
        halbjahr: 0,
        schuljahr,
      },
      {
        text: '4. Klasse, Jahreszeugnis',
        klassenstufe: 4,
        halbjahr: 1,
        schuljahr,
      },
    ];
  }

  getRemainingHalbjahre(zeugnissatz: Zeugnissatz): HalbjahrDescription[] {
    const halbjahre: HalbjahrDescription[] = [];
    const klassenstufeHalbjahr = zeugnissatz.klassenstufe + zeugnissatz.halbjahr / 2;
    const schuljahr = zeugnissatz.schuljahr - zeugnissatz.klassenstufe;
    if (klassenstufeHalbjahr < 1.5) {
      const newSchuljahr = schuljahr + 1;
      halbjahre.push({
        text: `1. Klasse, Jahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 1,
        halbjahr: 1,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 2.0) {
      const newSchuljahr = schuljahr + 2;
      halbjahre.push({
        text: `2. Klasse, Halbjahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 2,
        halbjahr: 0,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 2.5) {
      const newSchuljahr = schuljahr + 2;
      halbjahre.push({
        text: `2. Klasse, Jahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 2,
        halbjahr: 1,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 3.0) {
      const newSchuljahr = schuljahr + 3;
      halbjahre.push({
        text: `3. Klasse, Halbjahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 3,
        halbjahr: 0,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 3.5) {
      const newSchuljahr = schuljahr + 3;
      halbjahre.push({
        text: `3. Klasse, Jahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 3,
        halbjahr: 1,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 4.0) {
      const newSchuljahr = schuljahr + 4;
      halbjahre.push({
        text: `4. Klasse, Halbjahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 4,
        halbjahr: 0,
        schuljahr: newSchuljahr,
      });
    }
    if (klassenstufeHalbjahr < 4.5) {
      const newSchuljahr = schuljahr + 4;
      halbjahre.push({
        text: `4. Klasse, Jahreszeugnis ${newSchuljahr}/${newSchuljahr + 1}`,
        klassenstufe: 4,
        halbjahr: 1,
        schuljahr: newSchuljahr,
      });
    }

    return halbjahre;
  }

  getCategories(_zeugnis: BWZeugnis): FormularCategory[] {
    const categories: FormularCategory<BWFormularCategoryKey>[] = [];
    categories.push({ key: 'zeugnis', label: 'Zeugnis' });
    categories.push({ key: 'beilage', label: 'Beilage' });
    return categories;
  }

  getFormularType(zeugnis: BWZeugnis, category: BWFormularCategoryKey): Type<BWFormular> | null {
    switch (category) {
      case 'zeugnis':
        return this.getZeugnisFormularType(zeugnis);
      case 'beilage':
        return BW1234hjBeilageFormular;
      default:
        return null;
    }
  }
  getZeugnisFormularType(zeugnis: BWZeugnis): Type<BWFormular> | null {
    const klassenstufe = zeugnis.zeugnissatz.klassenstufe;
    if (klassenstufe < 3) return BW12hjStandardFormular;
    else {
      if (zeugnis.zeugnissatz.halbjahr === 0) return BW34hStandardFormular;
      else return BW34jStandardFormular;
    }
  }

  getNoteCandidates(zeugnis: Zeugnis, _fachName: string): NoteCandidateDescription[] {
    const candidates: NoteCandidateDescription[] = [];
    if (zeugnis.zeugnissatz.halbjahr === 0) {
      candidates.push(
        { displayStringShort: '1', content: '1', value: 14 },
        { displayStringShort: '1-', content: '1 minus', value: 13 },
        { displayStringShort: '1-2', content: '1-2', value: 12.5 },
        { displayStringShort: '2+', content: '2 plus', value: 12 },
        { displayStringShort: '2', content: '2', value: 11 },
        { displayStringShort: '2-', content: '2 minus', value: 10 },
        { displayStringShort: '2-3', content: '2-3', value: 9.5 },
        { displayStringShort: '3+', content: '3 plus', value: 9 },
        { displayStringShort: '3', content: '3', value: 8 },
        { displayStringShort: '3-', content: '3 minus', value: 7 },
        { displayStringShort: '3-4', content: '3-4', value: 6.5 },
        { displayStringShort: '4+', content: '4 plus', value: 6 },
        { displayStringShort: '4', content: '4', value: 5 },
        { displayStringShort: '4-', content: '4 minus', value: 4 },
        { displayStringShort: '4-5', content: '4-5', value: 3.5 },
        { displayStringShort: '5+', content: '5 plus', value: 3 },
        { displayStringShort: '5', content: '5', value: 2 },
        { displayStringShort: '5-', content: '5 minus', value: 1 },
        { displayStringShort: '5-6', content: '5-6', value: 0.5 },
        { displayStringShort: '6+', content: '6 plus', value: 0.25 },
        { displayStringShort: '6', content: '6', value: 0 }
      );
    } else {
      candidates.push(
        { displayStringShort: '1', content: 'sehr gut', value: 1 },
        { displayStringShort: '2', content: 'gut', value: 2 },
        { displayStringShort: '3', content: 'befriedigend', value: 3 },
        { displayStringShort: '4', content: 'ausreichend', value: 4 },
        { displayStringShort: '5', content: 'mangelhaft', value: 5 },
        { displayStringShort: '6', content: 'ungenügend', value: 6 }
      );
    }
    candidates.push(
      { displayStringShort: 'b', content: 'befreit', value: 'Befreit' },
      { displayStringShort: 'n.f.', content: 'nicht feststellbar', value: 'NichtFeststellbar' },
      { displayStringShort: '/', content: '/', value: 'Slash' },
      { displayStringShort: '*', content: '*', value: 'Stern' }
    );
    return candidates;
  }

  getNoteSuffixCandidates(): string[] {
    return [];
  }

  getKopfnoteCandidates(_zeugnis: Zeugnis, _fachName: string): NoteCandidateDescription[] {
    const candidates: NoteCandidateDescription[] = [];
    return candidates;
  }

  getKopfnoteSuffixCandidates(): string[] {
    return [];
  }

  override getDefaultKPHeaderLabels(_zeugnis: Zeugnis, _category: string): string[][] {
    return [
      ['trifft', 'vollständig', 'zu'],
      ['trifft', 'überwiegend', 'zu'],
      ['trifft', 'teilweise', 'zu'],
      ['trifft', 'kaum', 'zu'],
    ];
  }
}

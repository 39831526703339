<span #cnt style="display: none">
  <ng-content></ng-content>
</span>
<div
  *ngFor="let line of content.lines"
  [fzRichText]="line.fragment"
  [ngStyle]="{
    'text-align-last': isBlocksatz && !line.isLastInParagraph ? 'justify' : 'left',
    'white-space': isBlocksatz ? 'nowrap' : 'pre',
  }"
  [ngClass]="line.className"
></div>
<ng-container *ngFor="let part of parts" fzBlock [content]="content" [backDeco]="backDeco" [frontDeco]="frontDeco">
  <ng-template #content="fzPortal" fzPortal>
    <fz-translate
      class="select-auto"
      fzTextInput
      fzFocusable
      [style.left.px]="0.1"
      (fzHasFocusChange)="hasFocus = $event"
      [tabindex]="isPartSelected(part) ? 0 : -1"
      (editableCopy)="onCopy($event)"
      (editablePaste)="onPaste($event)"
      (insert)="onInsert($event)"
      (replace)="onReplace($event)"
      (delete)="onDelete()"
      (backspace)="onBackspace()"
      (selectAll)="onSelectAll()"
      (selectWord)="onSelectWord($event)"
      (moveCaret)="onMoveCaret($event)"
      (pointCaret)="onPointCaret($event)"
      (setClass)="onSetClass($event)"
      (moveSelection)="onMoveSelection($event)"
      (contextmenu)="onContextmenu($event)"
      (focusin)="onFocusin($event)"
      (undoRedo)="onUndoRedo($event)"
    >
      <ng-container *ngFor="let line of part.lines; trackBy: trackByIndex">
        <fz-line
          #lineElement
          [placeholder]="lineCount === 1 ? placeholder : undefined"
          [fzHighlightedRichText]="line.fragment"
          [lineStart]="line.start"
          [lineLength]="line.length"
          [selectionStart]="selection.start"
          [selectionEnd]="selection.end"
          [hasFocus]="hasFocus"
          [hasBullet]="line.hasBullet"
          [bulletWidth]="line.bulletWidth"
          [bulletLength]="line.bulletLength"
          [endsWithNewline]="line.endsWithNewline"
          [implicitLastChar]="line.endsWithNewline ? '\n' : line.isLastInParagraph && line.isInLastParagraph ? '' : ' '"
          [style.top.px]="line.top - part.lines[0].top"
          [style.left.px]="line.indentWidth"
          [style.width.px]="contentProperties.width - line.indentWidth"
          [ngStyle]="{
            'text-align': isBlocksatz && !line.isLastInParagraph ? 'justify' : 'left',
            'white-space': isBlocksatz ? 'normal' : 'pre',
            'caret-color': selection.start === selection.end ? 'auto' : 'transparent',
          }"
          [ngClass]="line.className"
          [textRange]="line"
        ></fz-line>
      </ng-container>
    </fz-translate>
  </ng-template>
  <ng-template #backDeco="fzPortal" fzPortal>
    <div style="position: absolute" [fzSize]="part.rect">
      <div
        [ngClass]="{
          'plausi-none': field == null,
          'plausi-ok': field != null && field.error == null && field.warning == null,
          'plausi-error': field?.error,
          'plausi-warning': field?.warning,
        }"
      ></div>
    </div>
  </ng-template>
  <ng-template #frontDeco="fzPortal" fzPortal>
    <p-contextMenu #contextMenu appendTo="body" styleClass="w-60" [model]="contextMenuItems"></p-contextMenu>
    <div
      *ngIf="hasFocus && isPartSelected(part)"
      fzFocusable
      (fzHasFocusChange)="hasFocus = $event"
      [style.bottom.px]="part.lines[0].top - getSelectedLine(part).top + 6"
      [style.left.px]="-76 - 6"
      [ngStyle]="isMenuExpanded ? { 'width.px': 900 } : {}"
      class="floating fz-rich-text"
      tabindex="0"
    >
      <ng-container *ngIf="isMenuExpanded">
        <p-menubar [model]="menuItemsExpanded">
          <ng-template pTemplate="start">
            <p-toggleButton
              [(ngModel)]="isMenuExpanded"
              onIcon="pi pi-times"
              offIcon="pi pi-ellipsis-v"
            ></p-toggleButton>
          </ng-template>
          <ng-template pTemplate="end">
            <ng-container *ngIf="isMenuExpanded">
              <p-dropdown [options]="fontSizeFactorOptions" [(ngModel)]="fontSizeFactor"></p-dropdown>
            </ng-container>
          </ng-template>
        </p-menubar>
      </ng-container>
      <ng-container *ngIf="!isMenuExpanded">
        <p-menubar [model]="[]">
          <ng-template pTemplate="start">
            <p-toggleButton
              [(ngModel)]="isMenuExpanded"
              onIcon="pi pi-times"
              offIcon="pi pi-ellipsis-v"
            ></p-toggleButton>
          </ng-template>
        </p-menubar>
      </ng-container>
    </div>
  </ng-template>
</ng-container>

<ng-template fzTemplate="sidebar">
  <fz-he-schueler-typ></fz-he-schueler-typ>
  <fz-he-sav-typ></fz-he-sav-typ>
</ng-template>
<fz-formular
  name="HE234jStandard"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="138"
  [pageMarginBottomLast]="138"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
  [lastPageFooter]="formular.seitenfussLast"
>
  @if (model != null) {
    <fz-he-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-he-zeugniskopf>
    <fz-he-zeugnissatz fzFormularItem="zeugnissatz" fzContainer [model]="model.zeugnissatz"></fz-he-zeugnissatz>
    <fz-he-zeugnistyp fzFormularItem="zeugnistyp" fzContainer [model]="model.zeugnistyp"></fz-he-zeugnistyp>
    <fz-he-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-he-schueler>
    <fz-he-sav *ngIf="model.sav != null" fzFormularItem="sav" fzContainer [model]="model.sav"></fz-he-sav>
    <fz-section
      *ngIf="model.sozialLernArbeitsverhalten != null"
      fzFormularItem="sav"
      [label]="model.sozialLernArbeitsverhalten.label"
      headerClass="Ueberschriften2"
      headerPaddingBottom="normal"
    >
      <fz-verbal [field]="model.sozialLernArbeitsverhalten.textField" [width]="680"></fz-verbal>
    </fz-section>
    <fz-he-leistungen fzFormularItem="leistungen" fzContainer [model]="model.leistungen"></fz-he-leistungen>
    <fz-optional fzFormularItem="leistungenWahl" [field]="model.leistungenWahlVisibleField" label="Wahlunterricht">
      <fz-he-leistungen-wahl fzContainer [model]="model.leistungenWahl"></fz-he-leistungen-wahl>
    </fz-optional>
    <fz-section
      fzFormularItem="bemerkungen"
      [label]="model.bemerkungen.label"
      headerClass="Ueberschriften2"
      headerPaddingBottom="normal"
    >
      <fz-verbal [field]="model.bemerkungen.textField" [width]="680"></fz-verbal>
    </fz-section>
    <fz-he-versaeumnis fzFormularItem="versaeumnis" fzContainer [model]="model.versaeumnis"></fz-he-versaeumnis>
    <fz-he-versetzungsvermerk
      fzFormularItem="versetzungsvermerk"
      fzContainer
      *ngIf="model.versetzungsvermerk != null"
      [model]="model.versetzungsvermerk"
    ></fz-he-versetzungsvermerk>
    <fz-he-unterschrift fzFormularItem="unterschrift" fzContainer [model]="model.unterschrift"></fz-he-unterschrift>
  }
</fz-formular>

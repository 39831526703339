import NIFachKey from '../../../models/generated/ni-fach-key';
import NIBereichKey from '../../../models/generated/ni-bereich-key';
import { NISeitenkopfComponent } from '../projectables/ni-seitenkopf.component';
import { NISeitenfussComponent } from '../projectables/ni-seitenfuss.component';
import { NISeitenfussLastComponent } from '../projectables/ni-seitenfuss-last.component';
import { NIFormularCategoryKey } from '../ni-formularsatz';
import { FormularFach } from '../../../models/formular-fach';
import { FormularBereich } from '../../../models/formular-bereich';
import { Formular, SozialLernArbeitsverhalten } from '@modules/formular/formular';
import { ZeugnisTypField } from '../fields/ni-zeugnis-typ-field';
import { NIZeugnis } from '../../../models/ni-zeugnis';
import { TextField } from '../../../models/fields/text-field';
import { IntelliField } from '../../../models/fields/intelli-field';
import { NumberField } from '../../../models/fields/number-field';
import { SprachenportfolioSpracheField } from '../fields/ni-sprachenportfolio-sprache-field';
import { FormularFachList } from '../../../models/formular-fach-list';

class Deutsch extends FormularFach {
  sprechenZuhoeren = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.sprechenZuhoeren, {
      label: 'Sprechen und Zuhören',
      labelShort: 'Spr',
      kategorien: '2000\r\n1004\r\n2001\r\n1005',
      eigeneTextbausteinKey: 'Deutsch_Sprechen_Zuhoeren',
    })
  );
  texteVerfassen = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.texteVerfassen, {
      label: this.formular.zeugnis.zeugnissatz.schuljahr >= 2023 ? 'Schreiben' : 'Schreiben, Texte verfassen',
      labelShort: 'Schr',
      kategorien: '2000\r\n1008\r\n1010\r\n2001\r\n1009\r\n1011\r\n',
      eigeneTextbausteinKey: 'Deutsch_Schreiben_Texte_verfassen',
    })
  );
  lesen = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.lesen, {
      label:
        this.formular.zeugnis.zeugnissatz.schuljahr >= 2023
          ? 'Lesen - mit Texten und Medien umgehen'
          : 'Lesen und mit Texten umgehen',
      labelShort: 'Les',
      kategorien: '2000\r\n1006\r\n2001\r\n1007\r\n',
      eigeneTextbausteinKey: 'Deutsch_Lesen_Texte_umgehen',
    })
  );
  constructor(formular: Formular, label: string) {
    super(formular, NIFachKey.deutsch, {
      label,
      labelShort: 'De',
    });
  }
}

class Mathematik extends FormularFach {
  zahlenOperationen = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.zahlenOperationen, {
      label: 'Zahlen und Operationen',
      labelShort: 'ZuO',
      kategorien: '2000\r\n1014\r\n2001\r\n1015\r\n',
      eigeneTextbausteinKey: 'Mathe_Zahlen_Operationen',
    })
  );
  groessenMessen = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.groessenMessen, {
      label: 'Größen und Messen',
      labelShort: 'GuM',
      kategorien: '2001\r\n1017\r\n',
      eigeneTextbausteinKey: 'Mathe_Groessen_Messen',
    })
  );
  raumForm = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.raumForm, {
      label: 'Raum und Form',
      labelShort: 'RuF',
      kategorien: '2000\r\n1000\r\n',
      eigeneTextbausteinKey: 'Mathe_Raum_Form',
    })
  );
  datenZufall = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.datenZufall, {
      label: 'Daten und Zufall',
      labelShort: 'DuZ',
      eigeneTextbausteinKey: 'Mathe_Daten_Zufall',
    })
  );
  constructor(formular: Formular) {
    super(formular, NIFachKey.mathematik, {
      label: 'Mathematik',
      labelShort: 'Ma',
    });
  }
}

class Sachunterricht extends FormularFach {
  technik = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.technik, {
      label: 'Technik',
      labelShort: 'Tech',
      kategorien: '2000\r\n1018\r\n2001\r\n60',
      eigeneTextbausteinKey: 'Sachunterricht_Technik',
    })
  );
  natur = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.natur, {
      label: 'Natur',
      labelShort: 'Nat',
      kategorien: '2000\r\n1018\r\n2001\r\n60',
      eigeneTextbausteinKey: 'Sachunterricht_Natur',
    })
  );
  raum = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.raum, {
      label: 'Raum',
      labelShort: 'Raum',
      kategorien: '2000\r\n1018\r\n2001\r\n60',
      eigeneTextbausteinKey: 'Sachunterricht_Raum',
    })
  );
  gesellschaftPolitik = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.gesellschaftPolitik, {
      label:
        this.formular.zeugnis.zeugnissatz.schuljahr >= 2023
          ? 'Gesellschaft, Politik und Wirtschaft'
          : 'Gesellschaft und Politik',
      labelShort: 'GP',
      kategorien: '2000\r\n1018\r\n2001\r\n60',
      eigeneTextbausteinKey: 'Sachunterricht_Gesellschaft_Politik',
    })
  );
  zeitGeschichte = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.zeitGeschichte, {
      label: this.formular.zeugnis.zeugnissatz.schuljahr >= 2023 ? 'Zeit und Wandel' : 'Zeit und Geschichte',
      labelShort: 'ZG',
      kategorien: '2000\r\n1018\r\n2001\r\n60',
      eigeneTextbausteinKey: 'Sachunterricht_Zeit_Geschichte',
    })
  );

  constructor(formular: Formular) {
    super(formular, NIFachKey.sachunterricht, {
      label: 'Sachunterricht',
      labelShort: 'Sa',
      kategorien: '2000\r\n1018\r\n2001\r\n60',
      eigeneTextbausteinKey: 'Sachunterricht',
    });
  }
}

class Fremdsprache extends FormularFach {
  hoerverstehen = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.hoerverstehen, {
      label: 'Hör- und Hör-/Sehverstehen',
      labelShort: 'HV',
      eigeneTextbausteinKey: 'Fremdsprache_Hoerverstehen',
    })
  );
  muendlich = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.muendlich, {
      label: 'Sprechen',
      labelShort: 'Spr',
      eigeneTextbausteinKey: 'Fremdsprache_Sprechen',
    })
  );
  lesen = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.lesen, {
      label: 'Leseverstehen',
      labelShort: 'LV',
      eigeneTextbausteinKey: 'Fremdsprache_Leseverstehen',
    })
  );
  kommunikativeFertigkeiten = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.kommunikativeFertigkeiten, {
      label: 'Kommunikative Fertigkeiten',
      labelShort: 'KF',
      eigeneTextbausteinKey: 'Fremdsprache_Kommunikative_Fertigkeiten',
    })
  );
  verfuegungSprachlicheMittel = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.verfuegungSprachlicheMittel, {
      label: 'Verfügung über sprachliche Mittel',
      labelShort: 'VSM',
      eigeneTextbausteinKey: 'Fremdsprache_Verfuegung_sprachliche_Mittel',
    })
  );
  interkulturelleKompetenzen = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.interkulturelleKompetenzen, {
      label: 'Interkulturelle Kompetenzen',
      labelShort: 'IK',
      eigeneTextbausteinKey: 'Fremdsprache_Interkulturelle_Kompetenzen',
    })
  );
  methodenkompetenzen = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.methodenkompetenzen, {
      label: 'Methodenkompetenzen',
      labelShort: 'MK',
      eigeneTextbausteinKey: 'Fremdsprache_Methodenkompetenzen',
    })
  );

  constructor(formular: Formular) {
    super(formular, NIFachKey.fremdsprache, {
      label: 'Fremdsprache',
      labelShort: 'FS',
    });
  }
}

class LernstandDeutsch extends FormularFach {
  hoerverstehen = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.hoerverstehen, {
      label: 'Hör-/Hör-Sehverstehen',
      labelShort: 'A Hö',
      kategorien: '2000\r\n1004\r\n2001\r\n1005',
    })
  );
  lesen = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.lesen, {
      label: 'Leseverstehen',
      labelShort: 'A Les',
      kategorien: '2000\r\n1006\r\n2001\r\n1007\r\n',
    })
  );
  texteVerfassen = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.texteVerfassen, {
      label: 'Schreiben',
      labelShort: 'A Schr',
      kategorien: '2000\r\n1008\r\n1010\r\n2001\r\n1009\r\n1011\r\n',
    })
  );
  muendlich = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.muendlich, {
      label: 'Mündliche Kommunikation',
      labelShort: 'A Mü',
      kategorien: '2000\r\n1004\r\n2001\r\n1005',
    })
  );
  constructor(formular: Formular) {
    super(formular, NIFachKey.lernstandDeutsch, {
      label: 'A Spracherwerb Deutsch',
      labelShort: 'A',
    });
  }
}

class LernstandEnglisch extends FormularFach {
  hoerverstehen = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.hoerverstehen, {
      label: 'Hör-/Hör-Sehverstehen',
      labelShort: 'B Hö',
      eigeneTextbausteinKey: 'Lernstand_Englisch',
    })
  );
  sprechenZuhoeren = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.sprechenZuhoeren, {
      label: 'Sprechen',
      labelShort: 'B Spr',
      eigeneTextbausteinKey: 'Lernstand_Englisch_Sprechen',
    })
  );
  lesen = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.lesen, {
      label: 'Leseverstehen',
      labelShort: 'B Les',
      eigeneTextbausteinKey: 'Lernstand_Englisch_Leseverstehen',
    })
  );
  texteVerfassen = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.texteVerfassen, {
      label: 'Schreiben',
      labelShort: 'B Schr',
      eigeneTextbausteinKey: 'Lernstand_Englisch_Schreiben',
    })
  );
  sprachermittlung = this.add(
    new FormularBereich(this.formular, this, NIBereichKey.sprachermittlung, {
      label: 'Sprachermittlung',
      labelShort: 'B SE',
      eigeneTextbausteinKey: 'Lernstand_Englisch_Sprachmittlung',
    })
  );
  constructor(formular: Formular) {
    super(formular, NIFachKey.lernstandEnglisch, {
      label: 'B Englisch',
      labelShort: 'B',
    });
  }
}

export abstract class NIFormular extends Formular {
  zeugnisTyp: ZeugnisTypField;
  abgangsdatum = new TextField<NIZeugnis>(
    { label: 'Abgangsdatum', labelShort: 'AbgDat', property: 'abgangsdatum' },
    { object: this.zeugnis }
  );
  abgangsschuljahr = new TextField<NIZeugnis>(
    { label: 'Abgangsschuljahr', labelShort: 'AbgSchj', property: 'abgangsschuljahr' },
    { object: this.zeugnis }
  );
  interessen = new IntelliField<NIZeugnis>(
    {
      label: 'Interessen, Fähigkeiten, Fertigkeiten',
      labelShort: 'IFF',
      property: 'interessen',
      textbausteinKey: 'Interessen',
    },
    { object: this.zeugnis }
  );
  sozialLernArbeitsverhalten: SozialLernArbeitsverhalten;
  deutsch: Deutsch;
  mathematik: Mathematik;
  sachunterricht: Sachunterricht;
  bildendeKunst: FormularFach;
  musik: FormularFach;
  sport: FormularFach;
  gestalten: FormularFach;
  werken: FormularFach;
  religion: FormularFach;
  fremdsprache: Fremdsprache;
  herkunftssprache: FormularFach;
  fehltageGesamt: NumberField<NIFormular>;
  fehltageUnentschuldigt: NumberField<NIFormular>;

  lernstandDeutsch: LernstandDeutsch;
  lernstandEnglisch: LernstandEnglisch;
  lernstandMathematik: FormularFach;
  lernstandWeitereFaecher: FormularFach;
  lernstandMehrsprachigkeit: FormularFach;
  lernstandMethodenkompetenzen: FormularFach;
  lernstandEmpfehlungen = new IntelliField<NIZeugnis>(
    {
      label: 'G Empfehlungen für den weiteren Schulbesuch',
      labelShort: 'G',
      property: 'lernstandEmpfehlungen',
      textbausteinKey: 'Lernstand_Empfehlungen',
    },
    { object: this.zeugnis }
  );
  lernstandAufnahmeAm = new TextField<NIZeugnis>(
    { label: 'Aufnahme am', labelShort: 'Aufn', property: 'lernstandAufnahmeAm' },
    { object: this.zeugnis }
  );
  lernstandSprachfoerderAm = new TextField<NIZeugnis>(
    { label: 'Sprachförderung am', labelShort: 'SprFördDat', property: 'lernstandSprachfoerderAm' },
    { object: this.zeugnis }
  );
  lernstandSprachfoerderart = new TextField<NIZeugnis>(
    { label: 'Sprachförderart', labelShort: 'SprFörd', property: 'lernstandSprachfoerderart' },
    { object: this.zeugnis }
  );
  bezeichnungPaedagogischerMitarbeiter = new TextField<NIZeugnis>(
    {
      label: 'Bezeichnung pädagogischer Mitarbeiter',
      labelShort: 'BezPäd',
      property: 'bezeichnungPaedagogischerMitarbeiter',
      required: false,
    },
    { object: this.zeugnis }
  );
  geistigDeutsch: Deutsch;
  geistigSport: FormularFach;
  geistigGestalten: FormularFach;
  geistigWerken: FormularFach;
  geistigWerteNormen: FormularFach;
  personaleBildung: FormularFach;
  arbeitsgemeinschaften = new IntelliField<NIZeugnis>(
    {
      label: 'Teilnahme an folgenden Arbeitsgemeinschaften / Fördermaßnahmen',
      labelShort: 'AG',
      property: 'arbeitsgemeinschaften',
      textbausteinKey: 'Arbeitsgemeinschaften',
    },
    { object: this.zeugnis }
  );
  bericht: FormularFach;
  sprachenportfolioSprache = new SprachenportfolioSpracheField(this.zeugnis);
  lernFachList: FormularFachList;

  seitenkopf = NISeitenkopfComponent;
  seitenfuss = NISeitenfussComponent;
  seitenfussLast = NISeitenfussLastComponent;

  constructor(
    public override zeugnis: NIZeugnis,
    category: string
  ) {
    super(zeugnis, category);
    this.zeugnisTyp = new ZeugnisTypField(zeugnis);
    this.fehltageGesamt = new NumberField(
      { label: 'Fehltage gesamt', labelShort: 'gesTg', property: 'fehltageGesamtIntern', required: false, warn: true },
      { object: this }
    );
    this.fehltageUnentschuldigt = new NumberField<NIFormular>(
      {
        label: 'Fehltage unentschuldigt',
        labelShort: 'unentTg',
        property: 'fehltageUnentschuldigtIntern',
        required: false,
        warn: true,
      },
      { object: this }
    );
    this.sozialLernArbeitsverhalten = new SozialLernArbeitsverhalten(
      this,
      {
        label: 'Arbeits- und Sozialverhalten',
        labelShort: 'SLAV',
        isKopfnote: true,
      },
      {
        label: 'Sozialverhalten',
        labelShort: 'SV',
        isKopfnote: true,
        kategorien: '3001\r\n10\r\n11\r\n12\r\n1\r\n9000',
        eigeneTextbausteinKey: 'Sozialverhalten',
      },
      {
        label: 'Arbeitsverhalten',
        labelShort: 'AV',
        isKopfnote: true,
        kategorien: '3000\r\n31\r\n32\r\n33\r\n34\r\n35\r\n36\r\n37\r\n20\r\n21\r\n22\r\n23\r\n24\r\n9001\r\n9002\r\n',
        eigeneTextbausteinKey: 'Arbeitsverhalten',
      }
    );
    this.deutsch = new Deutsch(this, 'Deutsch');
    this.geistigDeutsch = new Deutsch(
      this,
      this.zeugnis.zeugnissatz.schuljahr >= 2023 ? 'Deutsch' : 'Kommunikation/Deutsch'
    );
    this.mathematik = new Mathematik(this);
    this.sachunterricht = new Sachunterricht(this);
    this.fremdsprache = new Fremdsprache(this);
    this.musik = new FormularFach(this, NIFachKey.musik, {
      label: 'Musik',
      labelShort: 'Mu',
      kategorien: '2000\r\n1019\r\n2001\r\n80',
      eigeneTextbausteinKey: 'MusikText',
    });
    this.sport = new FormularFach(this, NIFachKey.sport, {
      label: 'Sport',
      labelShort: 'Sp',
      kategorien: '2001\r\n90',
      eigeneTextbausteinKey: 'SportText',
    });
    this.geistigSport = new FormularFach(this, NIFachKey.sport, {
      label: this.zeugnis.zeugnissatz.schuljahr >= 2023 ? 'Sport' : 'Bewegung/Sport',
      labelShort: 'Sp',
      kategorien: '2001\r\n90',
      eigeneTextbausteinKey: 'SportText',
    });
    this.bildendeKunst = new FormularFach(this, NIFachKey.bildendeKunst, {
      label: 'Kunst*)',
      labelShort: 'BK',
      kategorien: '2000\r\n1021\r\n2001\r\n70',
      eigeneTextbausteinKey: 'KunstText',
    });
    this.gestalten = new FormularFach(this, NIFachKey.gestalten, {
      label: 'Textiles Gestalten',
      labelShort: 'TG',
      kategorien: '2000\r\n1021\r\n2001\r\n70',
      eigeneTextbausteinKey: 'Textiles Gestalten',
    });
    this.geistigGestalten = new FormularFach(this, NIFachKey.gestalten, {
      label: 'Gestalten',
      labelShort: 'Ges',
      kategorien: '2000\r\n1021\r\n2001\r\n70',
      eigeneTextbausteinKey: 'Gestalten',
    });
    this.werken = new FormularFach(this, NIFachKey.werken, {
      label: 'Gestaltendes Werken',
      labelShort: 'GW',
      kategorien: '2000\r\n1021\r\n2001\r\n70',
      eigeneTextbausteinKey: 'Werken',
    });
    this.geistigWerken = new FormularFach(this, NIFachKey.werken, {
      label: 'Hauswirtschaft',
      labelShort: 'HW',
      kategorien: '2000\r\n1021\r\n2001\r\n70',
      eigeneTextbausteinKey: 'Werken',
    });
    this.religion = new FormularFach(this, NIFachKey.religion, {
      label: 'Religion',
      labelShort: 'Re',
      kategorien: '2000\r\n1003',
      eigeneTextbausteinKey: 'Religion',
    });
    this.geistigWerteNormen = new FormularFach(this, NIFachKey.werteNormen, {
      label: 'Werte und Normen',
      labelShort: 'WN',
      eigeneTextbausteinKey: 'WerteNormen',
    });
    this.herkunftssprache = new FormularFach(this, NIFachKey.herkunftssprache, {
      label: 'Herkunftssprachenunterricht',
      labelShort: 'He',
      eigeneTextbausteinKey: 'KursHerkunftsspracheText',
    });
    this.personaleBildung = new FormularFach(this, NIFachKey.personaleBildung, {
      label: 'Personale Bildung',
      labelShort: 'PB',
      eigeneTextbausteinKey: 'PersonaleBiildung',
    });
    this.lernstandDeutsch = new LernstandDeutsch(this);
    this.lernstandEnglisch = new LernstandEnglisch(this);
    this.lernstandMathematik = new FormularFach(this, NIFachKey.lernstandMathematik, {
      label: 'C Mathematik',
      labelShort: 'C',
      kategorien: '2000\r\n1014\r\n2001\r\n1015\r\n2001\r\n1017\r\n2000\r\n1000\r\n',
      eigeneTextbausteinKey: 'Lernstand_Mathematik',
    });
    this.lernstandWeitereFaecher = new FormularFach(this, NIFachKey.lernstandWeitereFaecher, {
      label: 'D Weitere Fächer',
      labelShort: 'D',
      kategorien: '2000\r\n1003\r\n1018\r\n1019\r\n1020\r\n1021\r\n1002\r\n2001\r\n60\r\n80\r\n90\r\n70',
      eigeneTextbausteinKey: 'Lernstand_WeitereFaecher',
    });
    this.lernstandMehrsprachigkeit = new FormularFach(this, NIFachKey.lernstandMehrsprachigkeit, {
      label: 'E Mehrsprachigkeit',
      labelShort: 'E',
      eigeneTextbausteinKey: 'Lernstand_Mehrsprachigkeit',
    });
    this.lernstandMethodenkompetenzen = new FormularFach(this, NIFachKey.lernstandMethodenkompetenzen, {
      label: 'F Methodenkompetenzen',
      labelShort: 'F',
      eigeneTextbausteinKey: 'Lernstand_Methodenkompetenzen',
    });
    this.bericht = new FormularFach(this, NIFachKey.bericht, {
      label: 'Bericht',
      labelShort: 'Ber',
      kategorien:
        '31\r\n32\r\n33\r\n34\r\n35\r\n36\r\n37\r\n20\r\n21\r\n22\r\n23\r\n24\r\n9001\r\n9002\r\n10\r\n11\r\n12\r\n1\r\n9000\r\n2000\r\n1003\r\n1004\r\n1006\r\n1008\r\n1010\r\n1001\r\n1000\r\n1014\r\n1016\r\n1018\r\n1019\r\n1020\r\n1021\r\n1002\r\n2001\r\n1005\r\n1007\r\n1009\r\n1011\r\n1015\r\n1017\r\n60\r\n80\r\n90\r\n70',
      eigeneTextbausteinKey: 'Freier_Text',
    });
    this.lernFachList = new FormularFachList(
      this,
      NIFachKey.lernFach,
      '31\r\n32\r\n33\r\n34\r\n35\r\n36\r\n37\r\n20\r\n21\r\n22\r\n23\r\n24\r\n9001\r\n9002\r\n10\r\n11\r\n12\r\n1\r\n9000\r\n2000\r\n1003\r\n1004\r\n1006\r\n1008\r\n1010\r\n1001\r\n1000\r\n1014\r\n1016\r\n1018\r\n1019\r\n1020\r\n1021\r\n1002\r\n2001\r\n1005\r\n1007\r\n1009\r\n1011\r\n1015\r\n1017\r\n60\r\n80\r\n90\r\n70'
    );
  }

  get halbjahrLabel(): string {
    return this.zeugnis.zeugnissatz.halbjahr === 0 ? '1. Halbjahr' : '1. und 2. Halbjahr';
  }

  get fehltageGesamtIntern(): number | null {
    return this.zeugnis.fehltageEntschuldigt == null
      ? null
      : this.zeugnis.fehltageEntschuldigt + (this.zeugnis.fehltageUnentschuldigt ?? 0);
  }
  set fehltageGesamtIntern(value: number | null) {
    if (value == null) this.zeugnis.fehltageEntschuldigt = null;
    else {
      this.zeugnis.fehltageEntschuldigt = value - (this.zeugnis.fehltageUnentschuldigt ?? 0);
    }
  }

  get fehltageUnentschuldigtIntern(): number | null {
    return this.zeugnis.fehltageUnentschuldigt;
  }
  set fehltageUnentschuldigtIntern(value: number | null) {
    if (this.zeugnis.fehltageEntschuldigt != null) {
      this.zeugnis.fehltageEntschuldigt += (this.zeugnis.fehltageUnentschuldigt ?? 0) - (value ?? 0);
    }
    this.zeugnis.fehltageUnentschuldigt = value;
  }

  abstract get category(): NIFormularCategoryKey;
  abstract get faecher(): FormularFach[];

  getBereich(fachKey: string, bereichKey: string | null = null) {
    const bereich = this.faecher
      .find((f) => f.key === fachKey)
      ?.bereiche?.find((b) => (b.key ?? 'gesamt') === bereichKey);
    return bereich;
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { RPFormular } from '../formulare/rp-formular';
import { OptionsBase } from '../../formular/options-base';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { FormularService } from '../../formular/formular.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'fz-rp-protokoll-typ',
  templateUrl: 'rp-protokoll-typ.component.html',
})
export class RPProtokollTypComponent extends OptionsBase<RPFormular> implements OnInit, OnDestroy {
  message: Message = {
    detail: 'Alle Änderungen an den Könnensprofil-Kreuzen im Protokoll spiegeln sich auch im Zeugnis wider.',
    severity: 'warn',
    closable: false,
  };

  constructor(
    formularService: FormularService<RPFormular>,
    private readonly notificationService: NotificationService
  ) {
    super(formularService);
  }

  ngOnInit(): void {
    if (
      this.formular.zeugnis.schuelerTyp == 'Schuleingangsphase' &&
      this.formular.zeugnis.zeugnissatz.klassenstufe === 3 &&
      this.formular.zeugnis.zeugnissatz.schuljahr >= 2024
    )
      this.notificationService.addHeaderMessage(this.message);
  }

  ngOnDestroy(): void {
    this.notificationService.removeHeaderMessage(this.message);
  }
}

import { Component, Input } from '@angular/core';
import { CopyToAllArgs } from '@modules/projectables/linked-text.component';
import { ProjectableProvider } from '@modules/projectables/projectable';
import { provideInterfaceBy } from '@modules/shared/interface-provider';
import { HEZeugnis } from '../../../models/he-zeugnis';
import { HE234jStandardFormular } from '../formulare/he-234j-standard-formular.component';
import { HEPanelDecoratorBase } from './he-panel-decorator-base';
import { HEFormular } from '../formulare/he-formular';

@Component({
  selector: 'fz-he-leistungen',
  templateUrl: 'he-leistungen.component.html',
  styles: [':host { display: block; }'],
  providers: [provideInterfaceBy(ProjectableProvider, HELeistungenComponent)],
})
export class HELeistungenComponent extends HEPanelDecoratorBase {
  static createModel(formular: HEFormular) {
    return {
      religion: {
        labelField: formular.religionLabel,
        noteField: formular.religion.gesamt.note,
      },
      deutsch: {
        label: formular.deutsch.gesamt.description.label,
        noteField: formular.deutsch.gesamt.note,
      },
      sachunterricht: {
        label: formular.sachunterricht.gesamt.description.label,
        noteField: formular.sachunterricht.gesamt.note,
      },
      mathematik: {
        label: formular.mathematik.gesamt.description.label,
        noteField: formular.mathematik.gesamt.note,
      },
      bildendeKunst: {
        label: formular.bildendeKunst.gesamt.description.label,
        noteField: formular.bildendeKunst.gesamt.note,
      },
      musik: {
        label: formular.musik.gesamt.description.label,
        noteField: formular.musik.gesamt.note,
      },
      sport: {
        labelField: formular.sportLabel,
        noteField: formular.sport.gesamt.note,
      },
      fremdsprache:
        formular.zeugnis.zeugnissatz.klassenstufe >= 3
          ? {
              labelField: formular.fremdsprache.gesamt.label,
              noteField: formular.fremdsprache.gesamt.note,
            }
          : undefined,
    };
  }

  @Input() model: ReturnType<typeof HELeistungenComponent.createModel> | undefined;
  copyFremdspracheLabelToAll(e: CopyToAllArgs): void {
    for (const zeugnis of e.zeugnisse) {
      const formular = new HE234jStandardFormular(zeugnis as HEZeugnis, this.formular.category);
      formular.fremdsprache.gesamt.label.value = e.text;
    }
  }
}

<ng-template fzTemplate="sidebar">
  <fz-he-schueler-typ></fz-he-schueler-typ>
</ng-template>
<fz-formular
  name="HE123hjFlex"
  [pageMarginTop]="57"
  [pageMarginTopFirst]="57"
  [pageMarginBottom]="57"
  [pageMarginBottomLast]="57"
  [pageHeader]="formular.seitenkopf"
  [pageFooter]="formular.seitenfuss"
>
  @if (model != null) {
    <fz-he-zeugniskopf fzFormularItem="zeugniskopf" fzContainer></fz-he-zeugniskopf>
    <fz-he-zeugnissatz fzFormularItem="zeugnissatz" fzContainer [model]="model.zeugnissatz"></fz-he-zeugnissatz>
    <fz-he-zeugnistyp fzFormularItem="zeugnistyp" fzContainer [model]="model.zeugnistyp"></fz-he-zeugnistyp>
    <fz-he-schueler fzFormularItem="schueler" fzContainer [model]="model.schueler"></fz-he-schueler>
    <fz-section
      fzFormularItem="allgemeineBeurteilung"
      [label]="model.allgemeineBeurteilung.label"
      headerClass="Ueberschriften2"
      headerPaddingBottom="normal"
    >
      <fz-verbal [field]="model.allgemeineBeurteilung.textField" [width]="680"></fz-verbal>
    </fz-section>
    <fz-section
      fzFormularItem="bemerkungen"
      [label]="model.bemerkungen.label"
      headerClass="Ueberschriften2"
      headerPaddingBottom="normal"
    >
      <fz-intelli [field]="model.bemerkungen.textField" [width]="680"></fz-intelli>
    </fz-section>
    <fz-he-versaeumnis fzFormularItem="versaeumnis" fzContainer [model]="model.versaeumnis"></fz-he-versaeumnis>
    <fz-he-versetzungsvermerk
      fzFormularItem="versetzungsvermerk"
      fzContainer
      [model]="model.versetzungsvermerk"
    ></fz-he-versetzungsvermerk>
    <fz-he-unterschrift fzFormularItem="unterschrift" fzContainer [model]="model.unterschrift"></fz-he-unterschrift>
  }
</fz-formular>

import { FormularFach } from '../../../models/formular-fach';
import { RPSeitenfussLastComponent } from '../projectables/rp-seitenfuss-last.component';
import { RPSeitenfussComponent } from '../projectables/rp-seitenfuss.component';
import { RPSeitenkopfComponent } from '../projectables/rp-seitenkopf.component';
import { RPFormular } from './rp-formular';
import { RPFormularCategoryKey } from '../rp-formularsatz';

export abstract class RPZeugnisFormular extends RPFormular {
  seitenkopf = RPSeitenkopfComponent;
  seitenfuss = RPSeitenfussComponent;
  seitenfussLast = RPSeitenfussLastComponent;
  get category(): RPFormularCategoryKey {
    return 'zeugnis';
  }

  get zeugnisTypLabel(): string {
    if (this.isAbgang) return 'Abgangszeugnis';
    if (this.isAbschlussMoeglich && this.isAbschluss) return 'Abschlusszeugnis';
    else return this.zeugnissatz.zeugnisTypLabel;
  }

  get isKP(): boolean {
    const zeugnis = this.zeugnis;
    const zeugnissatz = this.zeugnis.zeugnissatz;
    const isKPActive =
      (zeugnissatz.klassenstufe >= 3 || zeugnissatz.schuljahr >= 2024) &&
      (zeugnis.zeugnissatz.raster?.isKPActive ?? false);
    if (zeugnis.schuelerTyp === 'Lernbehindert') return false;
    if (zeugnis.schuelerTyp === 'Schuleingangsphase' && zeugnissatz.klassenstufe === 3 && zeugnissatz.schuljahr < 2024)
      return false;
    if (zeugnis.zeugnisTyp !== 'Abgang') {
      return (
        (zeugnissatz.halbjahr === 1 ||
          (zeugnis.schuelerTyp === 'Schuleingangsphase' &&
            zeugnissatz.klassenstufe === 3 &&
            zeugnissatz.schuljahr >= 2024)) &&
        isKPActive &&
        !zeugnis.ausnahmeVerbal
      );
    } else {
      return (
        isKPActive &&
        (zeugnis.abgangszeugnisTyp === null ||
          zeugnis.abgangszeugnisTyp === 'KP' ||
          zeugnis.abgangszeugnisTyp === 'KPVerbal')
      );
    }
  }

  isVerbal(fach: FormularFach): boolean {
    if (this.isAbgang) {
      if (this.zeugnis.abgangszeugnisTyp === 'KPVerbal' || this.zeugnis.abgangszeugnisTyp === 'Verbal') return true;
      if (this.zeugnis.zeugnissatz.klassenstufe <= 2 && this.zeugnis.zeugnissatz.schuljahr < 2024) return true;
      if (!this.isKP) return true;
      return fach.kpRowsHidden.length > 0 || fach.gesamt.bereich.note?.typ === 'Slash';
    } else {
      if (
        this.zeugnis.zeugnissatz.halbjahr === 0 &&
        !(
          this.zeugnis.schuelerTyp === 'Schuleingangsphase' &&
          this.zeugnissatz.klassenstufe === 3 &&
          this.zeugnissatz.schuljahr >= 2024
        )
      ) {
        if (this.zeugnis.schuelerTyp == null) return fach.gesamt.isVisibleOverrideTextDefaultFalse;
        else return true;
      } else {
        if (!this.isKP) return true;
        return fach.kpRowsHidden.length > 0 || fach.gesamt.bereich.note?.typ === 'Slash';
      }
    }
  }
}

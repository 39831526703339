import HEFachKey from '../../../models/generated/he-fach-key';
import HEBereichKey from '../../../models/generated/he-bereich-key';
import { HEFormularCategoryKey } from '../he-formularsatz';
import { HESeitenkopfComponent } from '../projectables/he-seitenkopf.component';
import { HESeitenfussComponent } from '../projectables/he-seitenfuss.component';
import { HESeitenfussLastComponent } from '../projectables/he-seitenfuss-last.component';
import { HESeitenfussLastFoerderschwerpunktComponent } from '../projectables/he-seitenfuss-last-foerderschwerpunkt.component';
import { FormularFach } from '../../../models/formular-fach';
import { BereichDescription, FormularBereich } from '../../../models/formular-bereich';
import { Formular, SozialLernArbeitsverhalten } from '@modules/formular/formular';
import { ZeugnisTypField } from '../fields/he-zeugnis-typ-field';
import { NumberField } from '../../../models/fields/number-field';
import { DropdownField } from '../../../models/fields/dropdown-field';
import { ValueField } from '../../../models/fields/value-field';
import { AnlageTypField } from '../fields/he-anlage-typ-field';
import { VerbalDescription, VerbalField } from '../../../models/fields/verbal-field';
import { TextField } from '../../../models/fields/text-field';
import { DateField } from '../../../models/fields/date-field';
import { Zeugnis } from '../../../models/zeugnis';
import { Bereich } from '../../../models/bereich';
import { Zeugnissatz } from '../../../models/zeugnissatz';
import { HEZeugnis } from '../../../models/he-zeugnis';
import { KlassenstufeFlexField } from '../fields/he-klassenstufe-flex-typ-field';
import { FormularFachList } from '../../../models/formular-fach-list';
import { AnlageInhaltTyp } from '../../../models/enums/anlage-inhalt-typ';

class Deutsch extends FormularFach {
  sprechenZuhoeren: FormularBereich;
  lesen: FormularBereich;
  texteVerfassen: FormularBereich;
  spracheUntersuchen: FormularBereich;
  constructor(
    formular: Formular,
    gesamt: BereichDescription,
    sprechenZuhoeren: BereichDescription,
    lesen: BereichDescription,
    texteVerfassen: BereichDescription,
    spracheUntersuchen: BereichDescription
  ) {
    super(formular, HEFachKey.deutsch, gesamt);
    this.sprechenZuhoeren = this.add(
      new FormularBereich(formular, this, HEBereichKey.sprechenZuhoeren, sprechenZuhoeren)
    );
    this.lesen = this.add(new FormularBereich(formular, this, HEBereichKey.lesen, lesen));
    this.texteVerfassen = this.add(new FormularBereich(formular, this, HEBereichKey.texteVerfassen, texteVerfassen));
    this.spracheUntersuchen = this.add(
      new FormularBereich(formular, this, HEBereichKey.spracheUntersuchen, spracheUntersuchen)
    );
  }
}

class DeutschIntensiv extends FormularFach {
  zuhoeren: FormularBereich;
  lesen: FormularBereich;
  sprechen: FormularBereich;
  texteVerfassen: FormularBereich;
  constructor(
    formular: Formular,
    gesamt: BereichDescription,
    zuhoeren: BereichDescription,
    lesen: BereichDescription,
    sprechen: BereichDescription,
    texteVerfassen: BereichDescription
  ) {
    super(formular, HEFachKey.deutschIntensiv, gesamt);
    this.zuhoeren = this.add(new FormularBereich(formular, this, HEBereichKey.zuhoeren, zuhoeren));
    this.lesen = this.add(new FormularBereich(formular, this, HEBereichKey.lesen, lesen));
    this.sprechen = this.add(new FormularBereich(formular, this, HEBereichKey.sprechen, sprechen));
    this.texteVerfassen = this.add(new FormularBereich(formular, this, HEBereichKey.texteVerfassen, texteVerfassen));
  }
}

export abstract class HEFormular extends Formular {
  zeugnisTyp: ZeugnisTypField;
  klassenstufeFlex: KlassenstufeFlexField;
  allgemeineBeurteilung: FormularFach;
  sozialLernArbeitsverhalten: SozialLernArbeitsverhalten;
  religion: FormularFach;
  deutsch: Deutsch;
  sachunterricht: FormularFach;
  mathematik: FormularFach;
  bildendeKunst: FormularFach;
  musik: FormularFach;
  sport: FormularFach;
  fremdsprache: FormularFach;
  herkunftssprache: FormularFach;
  sprachkompetenz: FormularFach;
  deutschIntensiv: DeutschIntensiv;
  fehltageEntschuldigt: NumberField<Zeugnis>;
  fehltageUnentschuldigt: NumberField<Zeugnis>;
  fehlstundenEntschuldigt: NumberField<Zeugnis>;
  fehlstundenUnentschuldigt: NumberField<Zeugnis>;
  religionLabel: DropdownField<Bereich, string | null>;
  sportLabel: DropdownField<Bereich, string | null>;
  anlageKeineUnterschriften: ValueField<Zeugnissatz, boolean>;
  anlageTyp: AnlageTypField;
  anlageFreitext: VerbalField<HEZeugnis>;
  klasseLerngruppe: DropdownField<HEZeugnis, string | null>;
  intensivklasseTypGenitiv: DropdownField<HEZeugnis, string | null>;
  intensivklasseTyp: DropdownField<HEZeugnis, string | null>;
  schulbesuchsjahrText: TextField<HEZeugnis>;
  teilgenommen: TextField<HEZeugnis>;
  intensivklasseVon: DateField<HEZeugnis>;
  intensivklasseBis: DateField<HEZeugnis>;
  regelfachList: FormularFachList;
  freiwilligesFachList: FormularFachList;
  bemerkungenDeutsch: VerbalField<Zeugnis, VerbalDescription<Zeugnis>>;

  seitenkopf = HESeitenkopfComponent;
  seitenfuss = HESeitenfussComponent;
  seitenfussLast = HESeitenfussLastComponent;
  seitenfussLastFoerderschwerpunkt = HESeitenfussLastFoerderschwerpunktComponent;

  constructor(
    public override zeugnis: HEZeugnis,
    category: string
  ) {
    super(zeugnis, category);
    this.zeugnisTyp = new ZeugnisTypField(zeugnis);
    this.klassenstufeFlex = new KlassenstufeFlexField(zeugnis);
    this.fehltageEntschuldigt = new NumberField(
      {
        label: 'Fehltage entschuldigt',
        labelShort: 'entTg',
        property: 'fehltageEntschuldigt',
        required: false,
        warn: true,
      },
      { object: zeugnis }
    );
    this.fehltageUnentschuldigt = new NumberField(
      {
        label: 'Fehltage unentschuldigt',
        labelShort: 'unentTg',
        property: 'fehltageUnentschuldigt',
        required: false,
        warn: true,
      },
      { object: zeugnis }
    );
    this.fehlstundenEntschuldigt = new NumberField(
      {
        label: 'Fehlstunden entschuldigt',
        labelShort: 'entStd',
        property: 'fehlstundenEntschuldigt',
        required: false,
        warn: true,
      },
      { object: zeugnis }
    );
    this.fehlstundenUnentschuldigt = new NumberField(
      {
        label: 'Fehlstunden unentschuldigt',
        labelShort: 'unentStd',
        property: 'fehlstundenUnentschuldigt',
        required: false,
        warn: true,
      },
      { object: zeugnis }
    );
    this.allgemeineBeurteilung = new FormularFach(this, HEFachKey.allgemeineBeurteilung, {
      label: 'Allgemeine Beurteilung',
      labelShort: 'AB',
      kategorien:
        '1\r\n9000\r\n9001\r\n9002\r\n9003\r\n9004\r\n10\r\n11\r\n12\r\n20\r\n21\r\n22\r\n23\r\n24\r\n31\r\n32\r\n33\r\n34\r\n35\r\n36\r\n37\r\n40\r\n41\r\n42\r\n43\r\n44\r\n45\r\n46\r\n47\r\n50\r\n51\r\n52\r\n53\r\n54\r\n55\r\n56\r\n2001\r\n60\r\n70\r\n80\r\n90\r\n100\r\n110\r\n2000\r\n1002',
      eigeneTextbausteinKey: 'Verbalbeurteilung',
    });
    this.sozialLernArbeitsverhalten = new SozialLernArbeitsverhalten(
      this,
      {
        zusatzTextRequired: true,
        label: 'Arbeits- und Sozialverhalten',
        labelShort: 'SLAV',
        isKopfnote: true,
        kategorien:
          '31\r\n32\r\n33\r\n34\r\n35\r\n36\r\n37\r\n20\r\n21\r\n22\r\n23\r\n24\r\n10\r\n11\r\n12\r\n1\r\n9002\r\n9000\r\n',
        eigeneTextbausteinKey: 'AVHSVH',
      },
      {
        label: 'Sozialverhalten',
        labelShort: 'SV',
        isKopfnote: true,
        eigeneTextbausteinKey: 'SozialverhaltenText',
      },
      {
        label: 'Arbeitsverhalten',
        labelShort: 'AV',
        isKopfnote: true,
        eigeneTextbausteinKey: 'ArbeitsverhaltenText',
      }
    );
    this.religion = new FormularFach(this, HEFachKey.religion, {
      label: 'Religion',
      labelShort: 'Re',
      kategorien: '2000\r\n1003',
      eigeneTextbausteinKey: 'ReligionText',
    });
    this.deutsch = new Deutsch(
      this,
      {
        label: 'Deutsch',
        labelShort: 'De',
        kategorien: '2000\r\n1004\r\n1008\r\n1010\r\n1006\r\n1001\r\n2001\r\n1005\r\n1009\r\n1011\r\n1007',
        eigeneTextbausteinKey: 'DeutschText',
      },
      {
        label: 'Sprechen und Zuhören',
        labelShort: 'Spr',
        kategorien: '2000\r\n1004\r\n2001\r\n1005',
        eigeneTextbausteinKey: 'Deutsch_Sprechen_Zuhoeren',
      },
      {
        label: 'Lesen und Rezipieren - mit literarischen und nichtliterarischen Texten / Medien umgehen',
        labelShort: 'Les',
        kategorien: '2000\r\n1006\r\n2001\r\n1007\r\n',
        eigeneTextbausteinKey: 'Deutsch_Lesen_UmgangTxtMedien',
      },
      {
        label: 'Schreiben',
        labelShort: 'Schr',
        kategorien: '2000\r\n1008\r\n1010\r\n2001\r\n1009\r\n1011\r\n',
        eigeneTextbausteinKey: 'Deusch_Texte_verfassen',
      },
      {
        label: 'Sprache und Sprachgebrauch untersuchen und reflektieren',
        labelShort: 'SU',
        kategorien: '2000\r\n1001',
        eigeneTextbausteinKey: 'Deutsch_Sprache_untersuchen',
      }
    );
    this.sachunterricht = new FormularFach(this, HEFachKey.sachunterricht, {
      label: 'Sachunterricht',
      labelShort: 'Sa',
      kategorien: '2000\r\n1018\r\n2001\r\n60',
      eigeneTextbausteinKey: 'SachunterrichtText',
    });
    this.mathematik = new FormularFach(this, HEFachKey.mathematik, {
      label: 'Mathematik',
      labelShort: 'Ma',
      kategorien: '2000\r\n1000\r\n1014\r\n9004\r\n50\r\n51\r\n52\r\n53\r\n54\r\n55\r\n56\r\n',
      eigeneTextbausteinKey: 'MathematikText',
    });
    this.musik = new FormularFach(this, HEFachKey.musik, {
      label: 'Musik',
      labelShort: 'Mu',
      kategorien: '2000\r\n1019\r\n2001\r\n80',
      eigeneTextbausteinKey: 'MusikText',
    });
    this.sport = new FormularFach(this, HEFachKey.sport, {
      label: 'Sport',
      labelShort: 'Sp',
      kategorien: '2001\r\n90',
      eigeneTextbausteinKey: 'SportText',
    });
    this.bildendeKunst = new FormularFach(this, HEFachKey.bildendeKunst, {
      label: 'Kunst*)',
      labelShort: 'BK',
      kategorien: '2000\r\n1021\r\n2001\r\n70',
      eigeneTextbausteinKey: 'KunstText',
    });
    this.fremdsprache = new FormularFach(this, HEFachKey.fremdsprache, {
      label: 'Einführung in eine Fremdsprache**)',
      labelShort: 'Fr',
      eigeneTextbausteinKey: 'EinfFremdspracheText',
    });
    this.herkunftssprache = new FormularFach(this, HEFachKey.herkunftssprache, {
      label: 'Herkunftssprachenunterricht',
      labelShort: 'He',
      eigeneTextbausteinKey: 'KursHerkunftsspracheText',
    });
    this.sprachkompetenz = new FormularFach(this, HEFachKey.sprachkompetenz, {
      label: 'Sprachkompetenz',
      labelShort: 'Sk',
      eigeneTextbausteinKey: 'KursFörderSprachkompetenzText',
    });
    this.deutschIntensiv = new DeutschIntensiv(
      this,
      {
        label: 'Niveau in Deutsch',
        labelShort: 'GeR',
      },
      {
        label: 'Hören',
        labelShort: 'Hö',
      },
      {
        label: 'Lesen',
        labelShort: 'Les',
      },
      {
        label: 'Sprechen',
        labelShort: 'Spre',
      },
      {
        label: 'Schreiben',
        labelShort: 'Schr',
      }
    );
    this.religionLabel = new DropdownField(
      {
        label: 'Religion/Ethik',
        labelShort: 'R/E',
        required: false,
        property: 'label',
        keyPrefix: 'religion.gesamt.bereich',
        candidates: [
          { value: null, displayStringShort: 'Religion/Ethik', content: 'Religion/Ethik' },
          { value: 'Religion' },
          { value: 'Ethik' },
          { value: 'Islamunterricht' },
        ],
      },
      { object: this.religion.gesamt.bereich }
    );
    this.sportLabel = new DropdownField(
      {
        label: 'Sport/Schwimmen',
        labelShort: 'Sp/Schw',
        required: false,
        property: 'label',
        keyPrefix: 'sport.gesamt.bereich',
        candidates: [
          { value: null, displayStringShort: 'Sport', content: 'Sport' },
          { value: 'Schwimmen' },
          { value: 'Sport/Schwimmen' },
        ],
      },
      { object: this.sport.gesamt.bereich }
    );
    this.anlageTyp = new AnlageTypField(zeugnis);
    this.anlageKeineUnterschriften = new ValueField(
      {
        label: 'Unterschriften Anlage',
        labelShort: 'UnterschrAnl',
        property: 'anlageKeineUnterschriften',
        keyPrefix: 'zeugnissatz',
        ignoreReset: true,
      },
      { object: zeugnis.zeugnissatz }
    );
    this.anlageFreitext = new VerbalField(
      {
        label: 'Allgemeine Beurteilung',
        labelShort: 'AB',
        property: 'anlageFreitext',
        bereich: this.deutsch.gesamt,
      },
      { object: zeugnis }
    );
    this.klasseLerngruppe = new DropdownField(
      {
        label: 'Klasse/Lerngruppe',
        labelShort: 'K/L',
        property: 'klasseLerngruppe',
        candidates: [
          { value: null, displayStringShort: '(nicht gewählt)', content: '' },
          { value: 'Klasse' },
          { value: 'Lerngruppe' },
        ],
      },
      { object: zeugnis }
    );
    this.schulbesuchsjahrText = new TextField(
      { label: 'Schulbesuchsjahr', labelShort: 'SchBesJ', property: 'schulbesuchsjahrText' },
      { object: zeugnis }
    );
    this.teilgenommen = new TextField(
      { label: 'Teilgenommen', labelShort: 'Teilg', property: 'teilgenommen' },
      { object: zeugnis }
    );
    this.intensivklasseTypGenitiv = new DropdownField(
      {
        label: 'Intensivklasse/-kurs',
        labelShort: 'IK',
        property: 'intensivklasseTyp',
        candidates: [
          { value: null, displayStringShort: '(nicht gewählt)', content: '' },
          { value: 'Intensivklasse', displayStringShort: 'Intensivklasse', content: 'einer Intensivklasse' },
          { value: 'Intensivkurs', displayStringShort: 'Intensivkurs', content: 'einem Intensivkurs' },
        ],
      },
      { object: zeugnis }
    );
    this.intensivklasseTyp = new DropdownField(
      {
        label: 'Intensivklasse/-kurs',
        labelShort: 'IK',
        property: 'intensivklasseTyp',
        candidates: [
          { value: null, displayStringShort: '(nicht gewählt)', content: '' },
          { value: 'Intensivklasse', displayStringShort: 'Intensivklasse', content: 'Intensivklasse' },
          { value: 'Intensivkurs', displayStringShort: 'Intensivkurs', content: 'Intensivkurs' },
        ],
      },
      { object: zeugnis }
    );
    this.intensivklasseVon = new DateField(
      { label: 'Intensivklasse von', labelShort: 'IKVon', property: 'intensivklasseVon' },
      { object: zeugnis }
    );
    this.intensivklasseBis = new DateField(
      { label: 'Intensivklasse bis', labelShort: 'IKBis', property: 'intensivklasseBis' },
      { object: zeugnis }
    );
    this.regelfachList = new FormularFachList(this, 'regelfach');
    this.freiwilligesFachList = new FormularFachList(this, 'freiwilligesFach');
    this.bemerkungenDeutsch = new VerbalField(
      {
        label: 'Bemerkungen',
        labelShort: 'Bem',
        property: 'bemerkungen',
        eigeneTextbausteinKey: 'Bemerkungen',
        linkedFaecher: [this.deutsch],
      },
      {
        object: zeugnis,
      }
    );
  }

  get klassenstufeLabel(): string {
    if (this.zeugnis.schuelerTyp !== 'Schuleingangsphase') return this.zeugnis.zeugnissatz.klassenstufe.toString();
    else return this.zeugnis.klassenstufeFlex ?? this.zeugnis.zeugnissatz.klassenstufe.toString();
  }

  get fehltageGesamt(): number | null {
    if (this.zeugnis.fehltageEntschuldigt == null && this.zeugnis.fehltageUnentschuldigt == null) return null;
    return (this.zeugnis.fehltageEntschuldigt ?? 0) + (this.zeugnis.fehltageUnentschuldigt ?? 0);
  }
  get fehlstundenGesamt(): number | null {
    if (this.zeugnis.fehlstundenEntschuldigt == null && this.zeugnis.fehlstundenUnentschuldigt == null) return null;
    return (this.zeugnis.fehlstundenEntschuldigt ?? 0) + (this.zeugnis.fehlstundenUnentschuldigt ?? 0);
  }

  abstract get category(): HEFormularCategoryKey;
  abstract get faecher(): FormularFach[];

  get anlageSavTyp(): AnlageInhaltTyp | null {
    if (this.sozialLernArbeitsverhalten.gesamt.isVisibleOverrideItemsDefaultFalse) return 'KP';
    if (this.sozialLernArbeitsverhalten.gesamt.isVisibleOverrideZusatztextDefaultFalse) return 'Freitext';
    return null;
  }
  set anlageSavTyp(value: AnlageInhaltTyp | null) {
    this.sozialLernArbeitsverhalten.gesamt.isVisibleOverrideItemsDefaultFalse = value === 'KP';
    this.sozialLernArbeitsverhalten.gesamt.isVisibleOverrideZusatztextDefaultFalse = value === 'Freitext';
  }
}

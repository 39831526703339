import { Component } from '@angular/core';
import { FormularComponentBase } from '@modules/formular/formular-component-base';
import { FormularService } from '@modules/formular/formular.service';
import { NoteField } from '../../../models/fields/note-field';
import { FormularFach } from '../../../models/formular-fach';
import { HEFormular } from './he-formular';
import { HEZeugnisFormular } from './he-zeugnis-formular';
import { FormularViewType } from '../../formular/formular';
import { HEZeugnissatzComponent } from '../projectables/he-zeugnissatz.component';
import { HEZeugnistypComponent } from '../projectables/he-zeugnistyp.component';
import { HESchuelerComponent } from '../projectables/he-schueler.component';
import { HESavComponent } from '../projectables/he-sav.component';
import { HELeistungenComponent } from '../projectables/he-leistungen.component';
import { HELeistungenWahlComponent } from '../projectables/he-leistungen-wahl.component';
import { HEVersaeumnisComponent } from '../projectables/he-versaeumnis.component';
import { HEVersetzungsvermerkComponent } from '../projectables/he-versetzungsvermerk.component';
import { HEUnterschriftComponent } from '../projectables/he-unterschrift.component';

export class HE234jStandardFormular extends HEZeugnisFormular {
  get viewType(): FormularViewType {
    return HE234jStandardFormularComponent;
  }

  get faecher(): FormularFach[] {
    const faecher: FormularFach[] = [];
    return faecher;
  }

  override get noteFields(): NoteField[] {
    const noteFields: NoteField[] = [];
    if (this.sozialLernArbeitsverhalten.gesamt.isVisibleOverrideNoteDefaultTrue) {
      noteFields.push(
        this.sozialLernArbeitsverhalten.lernArbeitsverhalten.note,
        this.sozialLernArbeitsverhalten.sozialverhalten.note
      );
    }
    noteFields.push(
      this.religion.gesamt.note,
      this.deutsch.gesamt.note,
      this.sachunterricht.gesamt.note,
      this.mathematik.gesamt.note,
      this.bildendeKunst.gesamt.note,
      this.musik.gesamt.note,
      this.sport.gesamt.note
    );
    if (this.zeugnis.zeugnissatz.klassenstufe >= 3) {
      noteFields.push(this.fremdsprache.gesamt.note);
    }
    if (this.herkunftssprache.gesamt.isVisibleOverrideDefaultTrue) {
      noteFields.push(this.herkunftssprache.gesamt.note);
    }
    return noteFields;
  }
}

@Component({
  selector: 'fz-he-234j-standard-formular',
  templateUrl: 'he-234j-standard-formular.component.html',
})
export class HE234jStandardFormularComponent extends FormularComponentBase<HE234jStandardFormular> {
  static createModel(formular: HEFormular) {
    return formular.zeugnis.zeugnissatz.halbjahr === 1 || formular.zeugnis.zeugnissatz.klassenstufe >= 3
      ? {
          zeugnissatz: HEZeugnissatzComponent.createModel(formular),
          zeugnistyp: HEZeugnistypComponent.createModel(formular),
          schueler: HESchuelerComponent.createModel(formular),
          sav: formular.sozialLernArbeitsverhalten.gesamt.isVisibleOverrideNoteDefaultTrue
            ? HESavComponent.createModel(formular)
            : undefined,
          sozialLernArbeitsverhalten: formular.sozialLernArbeitsverhalten.gesamt.isVisibleOverrideTextDefaultFalse
            ? {
                label: formular.sozialLernArbeitsverhalten.gesamt.description.label,
                textField: formular.sozialLernArbeitsverhalten.gesamt.text,
              }
            : undefined,
          leistungen: HELeistungenComponent.createModel(formular),
          leistungenWahlVisibleField: formular.herkunftssprache.gesamt.isVisibleOverrideDefaultTrueField,
          leistungenWahl: formular.herkunftssprache.gesamt.isVisibleOverrideDefaultTrue
            ? HELeistungenWahlComponent.createModel(formular)
            : undefined,
          bemerkungen: {
            label: formular.bemerkungenDeutsch.description.label,
            textField: formular.bemerkungenDeutsch,
          },
          versaeumnis: HEVersaeumnisComponent.createModel(formular),
          versetzungsvermerk:
            formular.zeugnis.zeugnissatz.halbjahr === 1
              ? HEVersetzungsvermerkComponent.createModel(formular)
              : undefined,
          unterschrift: HEUnterschriftComponent.createModel(formular),
        }
      : undefined;
  }

  get model() {
    return HE234jStandardFormularComponent.createModel(this.formular);
  }
  constructor(formularService: FormularService<HE234jStandardFormular>) {
    super(formularService);
  }
}

<fz-panel class="Fusstext">
  <fz-panel
    class="Fusstext"
    [border]="borderDirection.top"
    *ngIf="formular.category === 'zeugnis' && formular.zeugnis.schuelerTyp === 'Intensiv'"
  >
    <div style="text-align: center">
      <fz-label>Erläuterungen:</fz-label>
    </div>
    <div style="text-align: center">
      <fz-label>sehr gut (1), gut (2), befriedigend (3), ausreichend (4), mangelhaft (5), ungenügend (6)</fz-label>
    </div>
    <div style="text-align: center">
      <fz-label
        >tg. = teilgenommen, m.E.tg. = mit Erfolg teilgenommen, m.g.E.tg. = mit gutem Erfolg teilgenommen</fz-label
      >
    </div>
  </fz-panel>
  <div class="Fusstext" style="margin-right: 6px; text-align: right">
    <fz-label>- es folgt Seite {{ pageIndex + 2 }} -</fz-label>
  </div>
</fz-panel>

import { SLFormularCategoryKey } from '../sl-formularsatz';
import SLFachKey from '../../../models/generated/sl-fach-key';
import SLBereichKey from '../../../models/generated/sl-bereich-key';
import { FormularFach } from '../../../models/formular-fach';
import { BereichDescription, FormularBereich } from '../../../models/formular-bereich';
import { Formular, SozialLernArbeitsverhalten } from '@modules/formular/formular';
import { ZeugnisTypField } from '../fields/sl-zeugnis-typ-field';
import { NumberField } from '../../../models/fields/number-field';
import { SLZeugnis } from '../../../models/sl-zeugnis';
import { IntelliField } from '../../../models/fields/intelli-field';
import { Zeugnis } from '../../../models/zeugnis';
import { VerbalField } from '../../../models/fields/verbal-field';
import { SprachenportfolioField } from '../fields/sl-sprachenportfolio-field';
import { TextField } from '../../../models/fields/text-field';
import { ValueField } from '../../../models/fields/value-field';
import { GespraechEinladungAnzahlTyp } from '../../../models/enums/gespraech-einladung-anzahl-typ';
import { EmpfehlungField } from '../fields/sl-empfehlung-field';
import { VersetzungsvermerkField } from '../fields/sl-versetzungsvermerk-field';

class Deutsch extends FormularFach {
  readonly sprechenZuhoeren: FormularBereich;
  readonly lesen: FormularBereich;
  readonly texteVerfassen: FormularBereich;
  readonly rechtschreiben: FormularBereich;
  constructor(
    formular: Formular,
    gesamt: BereichDescription,
    sprechenZuhoeren: BereichDescription,
    lesen: BereichDescription,
    texteVerfassen: BereichDescription,
    rechtschreiben: BereichDescription
  ) {
    super(formular, SLFachKey.deutsch, gesamt);
    this.sprechenZuhoeren = this.add(
      new FormularBereich(formular, this, SLBereichKey.sprechenZuhoeren, sprechenZuhoeren)
    );
    this.lesen = this.add(new FormularBereich(formular, this, SLBereichKey.lesen, lesen));
    this.texteVerfassen = this.add(new FormularBereich(formular, this, SLBereichKey.texteVerfassen, texteVerfassen));
    this.rechtschreiben = this.add(new FormularBereich(formular, this, SLBereichKey.rechtschreiben, rechtschreiben));
  }
}

export abstract class SLFormular extends Formular {
  zeugnisTyp: ZeugnisTypField;
  schulbesuchsjahr: NumberField<SLZeugnis>;
  sozialLernArbeitsverhalten: SozialLernArbeitsverhalten;
  besondereFaehigkeiten: IntelliField<SLZeugnis>;
  fachbezogeneLernLeistungsentwicklung: FormularFach;
  religion: FormularFach;
  deutsch: Deutsch;
  sachunterricht: FormularFach;
  mathematik: FormularFach;
  bildendeKunst: FormularFach;
  musik: FormularFach;
  sport: FormularFach;
  schrift: FormularFach;
  fehltageEntschuldigt: NumberField<Zeugnis>;
  fehltageUnentschuldigt: NumberField<Zeugnis>;
  fehlstundenEntschuldigt: NumberField<Zeugnis>;
  fehlstundenUnentschuldigt: NumberField<Zeugnis>;
  anlageFreitext: VerbalField<SLZeugnis>;
  erlaeuterungen: VerbalField<SLZeugnis>;
  sprachenportfolio: SprachenportfolioField;
  gespraechGefuehrtVon: TextField<SLZeugnis>;
  erziehungsberechtigte: IntelliField<SLZeugnis>;
  anschrift: IntelliField<SLZeugnis>;
  teilnehmer: IntelliField<SLZeugnis>;
  gespraechsinhalte: VerbalField<SLZeugnis>;
  vorgeseheneGespraechsinhalte: VerbalField<SLZeugnis>;
  weitereBesprechungspunkte: IntelliField<SLZeugnis>;
  vereinbarungen: IntelliField<SLZeugnis>;
  gespraechEinladungAnzahl: ValueField<SLZeugnis, GespraechEinladungAnzahlTyp | null>;
  gespraechEinladungAm: TextField<SLZeugnis>;
  lernentwicklungsbericht: VerbalField<SLZeugnis>;
  schriftlicheMitteilungHinweise: IntelliField<SLZeugnis>;
  entwicklungSozialverhalten: FormularFach;
  entwicklungArbeitshaltung: FormularFach;
  entwicklungLernLeistungsentwicklung: FormularFach;
  entwicklungDenkvermoegen: FormularFach;
  entwicklungAusdrucksfaehigkeit: FormularFach;
  entwicklungZusammenfassung: IntelliField<SLZeugnis>;
  entwicklungEmpfehlung: EmpfehlungField;
  foerderschullehrer: TextField<SLZeugnis>;
  versetzungsvermerkInfo: VersetzungsvermerkField;

  constructor(
    public override zeugnis: SLZeugnis,
    category: string
  ) {
    super(zeugnis, category);
    this.zeugnisTyp = new ZeugnisTypField(zeugnis);
    this.schulbesuchsjahr = new NumberField(
      {
        label: 'Schulbesuchsjahr',
        labelShort: 'SchBesJahr',
        property: 'schulbesuchsjahr',
      },
      { object: zeugnis }
    );
    this.fehltageEntschuldigt = new NumberField(
      {
        label: 'Fehltage entschuldigt',
        labelShort: 'entTg',
        property: 'fehltageEntschuldigt',
        required: false,
        warn: true,
      },
      { object: zeugnis }
    );
    this.fehltageUnentschuldigt = new NumberField(
      {
        label: 'Fehltage unentschuldigt',
        labelShort: 'unentTg',
        property: 'fehltageUnentschuldigt',
        required: false,
        warn: true,
      },
      { object: zeugnis }
    );
    this.fehlstundenEntschuldigt = new NumberField(
      {
        label: 'Fehlstunden entschuldigt',
        labelShort: 'entStd',
        property: 'fehlstundenEntschuldigt',
        required: false,
        warn: true,
      },
      { object: zeugnis }
    );
    this.fehlstundenUnentschuldigt = new NumberField(
      {
        label: 'Fehlstunden unentschuldigt',
        labelShort: 'unentStd',
        property: 'fehlstundenUnentschuldigt',
        required: false,
        warn: true,
      },
      { object: zeugnis }
    );
    this.sozialLernArbeitsverhalten = new SozialLernArbeitsverhalten(
      this,
      {
        label: 'Sozial-, Lern- und Arbeitsverhalten',
        labelShort: 'SLAV',
        isKopfnote: true,
      },
      {
        label: 'Sozialverhalten',
        labelShort: 'SV',
        isKopfnote: true,
        kategorien: '10\r\n11\r\n12\r\n1\r\n9000\r\n',
        eigeneTextbausteinKey: 'Verhalten_Text',
      },
      {
        label: 'Lern- und Arbeitsverhalten',
        labelShort: 'LAV',
        isKopfnote: true,
        kategorien: '31\r\n32\r\n33\r\n34\r\n35\r\n36\r\n37\r\n20\r\n21\r\n22\r\n23\r\n24\r\n9001\r\n9002',
        eigeneTextbausteinKey: 'Mitarbeit_Text',
      }
    );
    this.besondereFaehigkeiten = new IntelliField(
      {
        label: 'Besondere Fähigkeiten/Fertigkeiten',
        labelShort: 'Fäh',
        textbausteinKey: 'Faehigkeiten',
        property: 'besondereFaehigkeiten',
      },
      { object: zeugnis }
    );
    this.fachbezogeneLernLeistungsentwicklung = new FormularFach(this, SLFachKey.fachbezogeneLernLeistungsentwicklung, {
      label: 'Fachbezogene Lern- und Leistungsentwicklung',
      labelShort: 'FLLE',
      kategorien:
        '2000\r\n' + // nur kp!
        '1003\r\n' + // Religion kp
        '1004\r\n1006\r\n1008\r\n1010\r\n' + // Dt. kp
        '1018\r\n' + // Sa kp
        '1000\r\n1014\r\n1016\r\n' + // Ma. kp
        '1021\r\n' + //  BK kp
        '1019\r\n' + //  Mu kp
        '1020\r\n' + //  Sport kp
        '1002\r\n',
      eigeneTextbausteinKey: 'Verbalbeurteilung',
    });
    this.religion = new FormularFach(this, SLFachKey.religion, { label: 'Religion', labelShort: 'Re' });
    this.deutsch = new Deutsch(
      this,
      { label: 'Deutsch', labelShort: 'De' },
      { label: 'Sprechen und Zuhören', labelShort: 'Spr' },
      { label: 'Lesen', labelShort: 'Les' },
      { label: 'Texte verfassen', labelShort: 'Texte' },
      { label: 'Rechtschreiben', labelShort: 'Recht' }
    );
    this.sachunterricht = new FormularFach(this, SLFachKey.sachunterricht, {
      label: 'Sachunterricht',
      labelShort: 'Sa',
    });
    this.mathematik = new FormularFach(this, SLFachKey.mathematik, { label: 'Mathematik', labelShort: 'Ma' });
    this.bildendeKunst = new FormularFach(this, SLFachKey.bildendeKunst, {
      label: 'Bildende Kunst',
      labelShort: 'BK',
    });
    this.musik = new FormularFach(this, SLFachKey.musik, { label: 'Musik', labelShort: 'Mu' });
    this.sport = new FormularFach(this, SLFachKey.sport, { label: 'Sport', labelShort: 'Sp' });
    this.schrift = new FormularFach(this, SLFachKey.schrift, { label: 'Schrift', labelShort: 'Schr' });
    this.anlageFreitext = new VerbalField(
      {
        label: 'Anlage Freitext',
        labelShort: 'AnlFrei',
        property: 'anlageFreitext',
        kategorien:
          '10\r\n11\r\n12\r\n1\r\n9000\r\n' + // SV
          '31\r\n32\r\n33\r\n34\r\n35\r\n36\r\n37\r\n20\r\n21\r\n22\r\n23\r\n24\r\n9001\r\n9002' + // AV
          '2000\r\n' + // nur kp!
          '1003\r\n' + // Religion kp
          '1004\r\n1006\r\n1008\r\n1010\r\n' + // Dt. kp
          '1018\r\n' + // Sa kp
          '1000\r\n1014\r\n1016\r\n' + // Ma. kp
          '1021\r\n' + //  BK kp
          '1019\r\n' + //  Mu kp
          '1020\r\n' + //  Sport kp
          '1002\r\n', //  Schrift kp
        eigeneTextbausteinKey: 'AnlageFreitext',
        linkedFaecher: [this.fachbezogeneLernLeistungsentwicklung],
      },
      { object: zeugnis }
    );
    this.erlaeuterungen = new VerbalField(
      {
        label: 'Ergänzende Erläuterungen',
        labelShort: 'Erl',
        property: 'erlaeuterungen',
        kategorien:
          '10\r\n11\r\n12\r\n1\r\n9000\r\n' + // SV
          '31\r\n32\r\n33\r\n34\r\n35\r\n36\r\n37\r\n20\r\n21\r\n22\r\n23\r\n24\r\n9001\r\n9002' + // AV
          '2000\r\n' + // nur kp!
          '1003\r\n' + // Religion kp
          '1004\r\n1006\r\n1008\r\n1010\r\n' + // Dt. kp
          '1018\r\n' + // Sa kp
          '1000\r\n1014\r\n1016\r\n' + // Ma. kp
          '1021\r\n' + //  BK kp
          '1019\r\n' + //  Mu kp
          '1020\r\n' + //  Sport kp
          '1002\r\n', //  Schrift kp
        eigeneTextbausteinKey: 'ErgaenzendeErlaeuterungen',
      },
      { object: zeugnis }
    );
    this.sprachenportfolio = new SprachenportfolioField(zeugnis);
    this.gespraechGefuehrtVon = new TextField(
      { label: 'Gespräch geführt von', labelShort: 'GesprVon', property: 'gespraechGefuehrtVon' },
      { object: zeugnis }
    );
    this.erziehungsberechtigte = new IntelliField(
      {
        label: 'Erziehungsberechtigte',
        labelShort: 'Erzb',
        property: 'erziehungsberechtigte',
        textbausteinKey: 'Erziehungsberechtigte',
      },
      { object: zeugnis }
    );
    this.anschrift = new IntelliField(
      { label: 'Anschrift/Telefon', labelShort: 'Anschr', property: 'anschrift', textbausteinKey: 'Anschrift' },
      { object: zeugnis }
    );
    this.teilnehmer = new IntelliField(
      { label: 'Teilnehmer/innen', labelShort: 'TN', property: 'teilnehmer', textbausteinKey: 'Teilnehmer' },
      { object: zeugnis }
    );
    this.gespraechsinhalte = new VerbalField(
      {
        label: 'Zentrale Gesprächsinhalte',
        labelShort: 'Inh',
        property: 'gespraechsinhalte',
        kategorien:
          '10\r\n11\r\n12\r\n1\r\n9000\r\n' + // SV
          '31\r\n32\r\n33\r\n34\r\n35\r\n36\r\n37\r\n20\r\n21\r\n22\r\n23\r\n24\r\n9001\r\n9002\r\n' + // AV
          '2000\r\n' + // nur kp!
          '1003\r\n' + // Religion kp
          '1004\r\n1006\r\n1008\r\n1010\r\n' + // Dt. kp
          '1018\r\n' + // Sa kp
          '1000\r\n1014\r\n1016\r\n' + // Ma. kp
          '1021\r\n' + //  BK kp
          '1019\r\n' + //  Mu kp
          '1020\r\n' + //  Sport kp
          '1002\r\n', //  Schrift kp
        eigeneTextbausteinKey: 'Gespraechsinhalte',
      },
      { object: zeugnis }
    );
    this.vorgeseheneGespraechsinhalte = new VerbalField(
      {
        label: 'Vorgesehene zentrale Gesprächsinhalte und ggf. Vereinbarungen',
        labelShort: 'Inh',
        property: 'vorgeseheneGespraechsinhalte',
        kategorien:
          '10\r\n11\r\n12\r\n1\r\n9000\r\n' + // SV
          '31\r\n32\r\n33\r\n34\r\n35\r\n36\r\n37\r\n20\r\n21\r\n22\r\n23\r\n24\r\n9001\r\n9002\r\n' + // AV
          '2000\r\n' + // nur kp!
          '1003\r\n' + // Religion kp
          '1004\r\n1006\r\n1008\r\n1010\r\n' + // Dt. kp
          '1018\r\n' + // Sa kp
          '1000\r\n1014\r\n1016\r\n' + // Ma. kp
          '1021\r\n' + //  BK kp
          '1019\r\n' + //  Mu kp
          '1020\r\n' + //  Sport kp
          '1002\r\n', //  Schrift kp
        eigeneTextbausteinKey: 'VorgeseheneGespraechsinhalte',
      },
      { object: zeugnis }
    );
    this.weitereBesprechungspunkte = new IntelliField(
      {
        label: 'Weitere Besprechungspunkte',
        labelShort: 'Pkt',
        property: 'weitereBesprechungspunkte',
        textbausteinKey: 'WeitereBesprechungspunkte',
      },
      { object: zeugnis }
    );
    this.vereinbarungen = new IntelliField(
      { label: 'Vereinbarungen', labelShort: 'Vereinb', property: 'vereinbarungen', textbausteinKey: 'Vereinbarungen' },
      { object: zeugnis }
    );
    this.gespraechEinladungAnzahl = new ValueField(
      { label: 'Anzahl Gesprächseinladungen', labelShort: '#GesprEinl', property: 'gespraechEinladungAnzahl' },
      { object: zeugnis }
    );
    this.gespraechEinladungAm = new TextField(
      { label: 'Datum Gesprächseinladung', labelShort: 'DatGesprEinl', property: 'gespraechEinladungAm' },
      { object: zeugnis }
    );
    this.lernentwicklungsbericht = new VerbalField(
      {
        label: 'Lernentwicklungsbericht',
        labelShort: 'Entw',
        property: 'lernentwicklungsbericht',
        kategorien:
          '2000\r\n' + // nur kp!
          '1003\r\n' + // Religion kp
          '1004\r\n1006\r\n1008\r\n1010\r\n' + // Dt. kp
          '1018\r\n' + // Sa kp
          '1000\r\n1014\r\n1016\r\n' + // Ma. kp
          '1021\r\n' + //  BK kp
          '1019\r\n' + //  Mu kp
          '1020\r\n' + //  Sport kp
          '1002\r\n', //  Schrift kp
        eigeneTextbausteinKey: 'Lernentwicklungsbericht',
      },
      { object: zeugnis }
    );
    this.schriftlicheMitteilungHinweise = new IntelliField(
      {
        label:
          'Hinweise des Klassenlehrers/der Klassenlehrerin und ggf. des Förderschullehrers/der Förderschullehrerin',
        labelShort: 'Hinw',
        property: 'schriftlicheMitteilungHinweise',
        textbausteinKey: 'SchriftlicheMitteilungHinweise',
      },
      { object: zeugnis }
    );
    this.entwicklungSozialverhalten = new FormularFach(this, SLFachKey.entwicklungSozialverhalten, {
      label: 'a) Sozialverhalten',
      labelShort: 'SV',
      kategorien: '1\r\n9000\r\n11\r\n12',
      eigeneTextbausteinKey: 'EntwicklungsberichtC',
    });
    this.entwicklungArbeitshaltung = new FormularFach(this, SLFachKey.entwicklungArbeitshaltung, {
      label: 'b) Arbeitshaltung/Art des Arbeitens und Lernens',
      labelShort: 'AH',
      kategorien: '9002\r\n31\r\n33\r\n34\r\n37',
      eigeneTextbausteinKey: 'EntwicklungsberichtB',
    });
    this.entwicklungLernLeistungsentwicklung = new FormularFach(this, SLFachKey.entwicklungLernLeistungsentwicklung, {
      label: 'c) Bisherige Lern- und Leistungsentwicklung',
      labelShort: 'LE',
      kategorien: '9002\r\n35\r\n36\r\n',
      eigeneTextbausteinKey: 'EntwicklungsberichtA',
    });
    this.entwicklungDenkvermoegen = new FormularFach(this, SLFachKey.entwicklungDenkvermoegen, {
      label: 'd) Denkvermögen',
      labelShort: 'DV',
      kategorien: '9001\r\n22\r\n23',
      eigeneTextbausteinKey: 'EntwicklungsberichtD',
    });
    this.entwicklungAusdrucksfaehigkeit = new FormularFach(this, SLFachKey.entwicklungAusdrucksfaehigkeit, {
      label: 'e) Sprachliche Ausdrucksfähigkeit',
      labelShort: 'SA',
      kategorien: '9001\r\n21\r\n9003\r\n46',
      eigeneTextbausteinKey: 'EntwicklungsberichtE',
    });
    this.entwicklungZusammenfassung = new IntelliField(
      {
        label: 'Zusammenfassende Beurteilung',
        labelShort: 'Zus.Beurt.',
        property: 'entwicklungZusammenfassung',
        textbausteinKey: 'EntwicklungsberichtG',
      },
      { object: zeugnis }
    );
    this.entwicklungEmpfehlung = new EmpfehlungField(zeugnis);
    this.foerderschullehrer = new TextField(
      {
        label: 'Förderschullehrer',
        labelShort: 'FL',
        required: false,
        property: 'foerderschullehrer',
      },
      { object: zeugnis }
    );
    this.versetzungsvermerkInfo = new VersetzungsvermerkField(zeugnis);
  }

  abstract get category(): SLFormularCategoryKey;

  get isMitteilungAktiv(): boolean {
    return this.zeugnis.gespraechEinladungAnzahl != null && this.zeugnis.gespraechEinladungAnzahl !== 'gefolgt';
  }
  set isMitteilungAktiv(value: boolean) {
    if (value !== this.isMitteilungAktiv) {
      this.zeugnis.gespraechEinladungAnzahl = value ? 'eine' : 'gefolgt';
    }
  }

  get isErlaeuterungenAktiv(): boolean {
    return (
      (this.zeugnis.zeugnissatz.klassenstufe >= 3 ||
        (this.zeugnis.zeugnissatz.klassenstufe < 3 &&
          this.zeugnis.zeugnislevel === 2 &&
          !this.zeugnis.zeugnissatz.zeugniskopflizenz.slSchuleingangsphaseVerbal)) &&
      this.zeugnis.erlaeuterungenAktiv
    );
  }

  get isProtokoll(): boolean {
    return (
      this.zeugnis.zeugnissatz.halbjahr === 0 &&
      this.zeugnis.zeugnissatz.klassenstufe <= 2 &&
      (this.zeugnis.zeugnislevel === 1 || this.zeugnis.zeugnissatz.zeugniskopflizenz.slSchuleingangsphaseVerbal)
    );
  }

  get zeugnisTypLabel(): string {
    if (this.isAbgang) return 'Abgangszeugnis';
    else return this.zeugnissatz.zeugnisTypLabel;
  }
}

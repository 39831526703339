import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@modules/shared/shared.module';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { AngularResizeEventModule } from '../../angular-resize/angular-resize-event.module';
import { TbEditorTableComponent } from './tb-editor-table/tb-editor-table.component';
import { TbEditorComponent } from './tb-editor/tb-editor.component';

@NgModule({
  declarations: [TbEditorComponent, TbEditorTableComponent],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    TreeModule,
    TreeTableModule,
    TableModule,
    PanelModule,
    InputTextModule,
    InputTextareaModule,
    CheckboxModule,
    DropdownModule,
    ButtonModule,
    AngularResizeEventModule,
    DialogModule,
    TooltipModule,
    ToolbarModule,
    MultiSelectModule,
  ],
  exports: [TbEditorComponent, TbEditorTableComponent],
})
export class TextbausteinModule {}

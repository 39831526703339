import { Directive, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { NodeHelper, NodeWalker, TextPosition } from '@modules/dom';
import { SpellCheckerService } from './spell-checker.service';
import { WorkspaceService } from '../../shared/services';

@Directive({ selector: '[fzHighlightedRichText]' })
export class HighlightedRichTextDirective implements OnChanges {
  @Input() fzHighlightedRichText: DocumentFragment = document.createDocumentFragment();
  @Input() lineStart = 0;
  @Input() lineLength = 0;
  @Input() selectionStart = 0;
  @Input() selectionEnd = 0;
  @Input() hasFocus = false;
  @Input() hasBullet = false;
  @Input() bulletWidth = 0;
  @Input() bulletLength = 0;
  @Input() endsWithNewline = false;
  private readonly element: HTMLElement;
  constructor(
    elementRef: ElementRef,
    private readonly spellChecker: SpellCheckerService,
    private readonly workspaceService: WorkspaceService
  ) {
    this.element = elementRef.nativeElement;
  }

  get lineEnd() {
    return this.lineStart + this.lineLength;
  }

  ngOnChanges(_changes: SimpleChanges): void {
    let fragment = this.fzHighlightedRichText;
    fragment = this.spellChecker.checkFragment(
      fragment,
      this.workspaceService.selectedZeugnis?.schuelerVorname?.split(' ') ?? []
    );
    if (
      this.hasFocus &&
      this.selectionStart !== this.selectionEnd &&
      this.selectionEnd > this.lineStart &&
      this.lineEnd >= this.selectionStart
    ) {
      fragment = this.highlightText(fragment);
    }

    if (this.hasBullet) fragment = this.spanBullet(fragment);

    // Optimierung, falls keine Änderung
    if (
      fragment.childNodes.length !== this.element.childNodes.length ||
      !Array.from(fragment.childNodes).every((n, i) => n.isEqualNode(this.element.childNodes[i]))
    ) {
      NodeHelper.empty(this.element);
      this.element.appendChild(fragment);
    }
  }

  private highlightText(fragment: DocumentFragment): DocumentFragment {
    const resultFragment = document.createDocumentFragment();
    const walker = new NodeWalker(fragment);
    let posSelectionStart: TextPosition = {
      node: fragment,
      nodeOffset: 0,
      index: 0,
    };
    let posSelectionEnd: TextPosition = {
      node: fragment,
      nodeOffset: 0,
      index: 0,
    };
    for (const pos of walker.textPositions) {
      if (this.lineStart + pos.index <= this.selectionStart) posSelectionStart = pos;
      if (this.lineStart + pos.index <= this.selectionEnd) posSelectionEnd = pos;
    }
    resultFragment.appendChild(
      NodeHelper.cloneContents({
        startNode: fragment,
        startOffset: 0,
        endNode: posSelectionStart.node,
        endOffset: posSelectionStart.nodeOffset,
      })
    );
    const spanHighlight = document.createElement('span');
    spanHighlight.className = 'fz-highlight';
    spanHighlight.appendChild(
      NodeHelper.cloneContents({
        startNode: posSelectionStart.node,
        startOffset: posSelectionStart.nodeOffset,
        endNode: posSelectionEnd.node,
        endOffset: posSelectionEnd.nodeOffset,
      })
    );
    if (this.endsWithNewline && this.selectionStart <= this.lineEnd && this.lineEnd < this.selectionEnd) {
      spanHighlight.appendChild(document.createTextNode(String.fromCharCode(160)));
    }

    resultFragment.appendChild(spanHighlight);
    resultFragment.appendChild(
      NodeHelper.cloneContents({
        startNode: posSelectionEnd.node,
        startOffset: posSelectionEnd.nodeOffset,
        endNode: fragment,
        endOffset: fragment.childNodes.length,
      })
    );
    return resultFragment;
  }

  private spanBullet(fragment: DocumentFragment): DocumentFragment {
    fragment = fragment.cloneNode(true) as DocumentFragment;
    const resultFragment = document.createDocumentFragment();
    const walker = new NodeWalker(fragment);
    let posAfterBullet: TextPosition = {
      node: fragment,
      nodeOffset: 0,
      index: 0,
    };
    for (const pos of walker.textPositions) {
      if (pos.index === this.bulletLength) {
        posAfterBullet = pos;
        break;
      }
    }
    const spanBullet = document.createElement('span');
    spanBullet.style.display = 'inline-block';
    spanBullet.style.width = `${this.bulletWidth}px`;
    spanBullet.appendChild(
      NodeHelper.cloneContents({
        startNode: fragment,
        startOffset: 0,
        endNode: posAfterBullet.node,
        endOffset: posAfterBullet.nodeOffset,
      })
    );
    resultFragment.appendChild(spanBullet);
    resultFragment.appendChild(
      NodeHelper.cloneContents({
        startNode: posAfterBullet.node,
        startOffset: posAfterBullet.nodeOffset,
        endNode: fragment,
        endOffset: fragment.childNodes.length,
      })
    );

    return resultFragment;
  }
}
